import {useRef} from 'react';
import useDraggableScroll from 'use-draggable-scroll';

import './style.css';

const Carousel = (props) => {
    const ref = useRef(null);
    const {onMouseDown} = useDraggableScroll(ref);

    return (
        <div
            ref={ref} onMouseDown={onMouseDown}
            className="tw-overflow-x-scroll tw-flex tw-flex-nowrap tw-gap-4 cs-widgets-carousel-scrollbar"
        >
            {props.children}
        </div>
    );
};

export default Carousel;