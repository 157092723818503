import {Fragment} from 'react';

import temperatureGuideData from './data.json';
import './TemperatureGuide.css';

const TemperatureGuide = () => (
    <div>
        <div className="tw-px-7 tw-py-7 md:tw-px-[42px] tw-box-border time-temp-key">
            <div className="time-temp-left">
                <h3 className="tw-mb-0">Key</h3>
            </div>

            <div className="tw-flex-[1_0_auto]">
                <div className="tw-block tw-relative tw-h-[60px] tw-pb-3.5 tw-my-3.5 tw-mx-0 tw-border-0 tw-border-b tw-border-solid tw-border-light-gray md:tw-h-full md:tw-pb-0 md:tw-my-7 md:tw-border-b-0">
                    <div
                        className="tw-relative tw-max-w-[60%] tw-leading-[18px] tw-pt-[7px] tw-top-0 tw-left-0 tw-text-sm tw-font-bold tw-opacity-40 md:tw-pt-0 md:tw-absolute md:tw-max-w-max md:tw-leading-[14px]">
                        Beef: Steak Rare
                    </div>
                    <div
                        className="tw-relative tw-max-w-[60%] tw-leading-[18px] tw-bottom-0 tw-left-0 tw-flex md:tw-absolute md:tw-max-w-max md:tw-leading-[14px]">
                        <div>54 °C / 129 °F</div>
                    </div>

                    <div className="tw-group tw-absolute tw-h-[60px] tw-top-0 tw-right-0 tw-opacity-40 hover:tw-cursor-pointer md:tw-relative md:tw-h-10">
                        <div className="tw-hidden tw-opacity-100 tw-transition-opacity tw-duration-200 tw-ease-in-out group-hover:tw-opacity-100 md:tw-block">
                            <div className="tw-absolute tw-inset-x-0 tw-top-1/2 tw-translate-y-[-1px] tw-border tw-border-solid tw-border-gray" />
                            <div
                                className="tw-absolute tw-text-xs tw-leading-[14px] tw-top-0 tw-ml-[-14px]"
                                style={{left: '30.1667%'}}
                            >
                                00:01
                            </div>
                            <div
                                className="tw-absolute tw-text-xs tw-leading-[14px] tw-top-0 tw-ml-[-14px]"
                                style={{left: '36%'}}
                            >
                                00:30
                            </div>
                            <div
                                className="tw-absolute tw-text-xs tw-leading-[14px] tw-top-0 tw-ml-[-14px]"
                                style={{left: '75%'}}
                            >
                                6:00
                            </div>
                            <div
                                className="tw-absolute tw-text-xs tw-leading-[14px] tw-top-0 tw-ml-[-14px]"
                                style={{left: '85%'}}
                            >
                                12:00
                            </div>
                            <div
                                className="tw-absolute tw-text-xs tw-leading-[14px] tw-top-0 tw-ml-[-14px]"
                                style={{left: '93%'}}
                            >
                                24:00
                            </div>
                            <div
                                className="tw-absolute tw-text-xs tw-leading-[14px] tw-top-0 tw-ml-[-14px]"
                                style={{left: '99.6%'}}
                            >
                                48:00
                            </div>
                        </div>
                        <div className="time-temp-line" style={{width: '40%'}}/>
                        <div
                            className="tw-hidden tw-absolute tw-top-auto tw-bottom-0 tw-h-10 tw-rounded-full tw-bg-light-green tw-pr-10 tw-z-[2] md:tw-block md:tw-top-0"
                            style={{width: '13.5%', left: '44.5%'}}/>
                        <div
                            className="time-temp-circle tw-border-2 tw-border-solid tw-border-white tw-bg-dark-green tw-z-[200] tw-right-[50px] before:tw-content-['We_like'] md:tw-right-0 md:before:tw-content-['']"
                            style={{left: '44.5%'}}
                        >
                            1:30
                        </div>
                        <div
                            className="time-temp-circle tw-right-0 tw-transform-none tw-bg-light-green before:tw-content-['Max'] tw-h-[38px] tw-w-[38px] tw-border tw-border-solid tw-border-white tw-z-[100] md:tw-translate-x-px md:tw-translate-y-px md:before:tw-content-['']"
                            style={{left: '58%'}}
                        >
                            3:00
                        </div>
                    </div>
                    <div className="time-temp-label label-favorite">
                        <p>Our Favorite</p>
                    </div>
                    <div className="time-temp-label label-max">
                        <p>Last call</p>
                    </div>
                    <div className="time-temp-label label-range">
                        <p>Ready time</p>
                    </div>
                    <div className="time-temp-label label-temp">
                        <p>Water temperature</p>
                    </div>
                </div>
            </div>
        </div>

        {temperatureGuideData.map((item, i) => (
            <Fragment key={`${i}-temp-section`}>
                <div className="tw-px-7 tw-py-7 md:tw-px-[42px] tw-box-border">
                    {item.sectionContent.map((innerSection, innerIndex) => (
                        <Fragment key={`${i}.${innerIndex}-inner-temp-section`}>
                            <div className="time-temp-left">
                                {innerSection.left.heading && (
                                    <h3 className="tw-mb-0">{innerSection.left.heading}</h3>
                                )}
                                {innerSection.left.subHeading && (
                                    <p className="tw-pl-2">{innerSection.left.subHeading}</p>
                                )}
                            </div>

                            <div className="tw-flex-[1_0_auto]">
                                {innerSection.right.map((rightItem, rightItemIndex) => (
                                    <div
                                        key={`${i}.${rightItemIndex}-temp-item`}
                                        className="tw-block tw-relative tw-h-[60px] tw-pb-3.5 tw-my-3.5 tw-mx-0 tw-border-0 tw-border-b tw-border-solid tw-border-light-gray md:tw-h-full md:tw-pb-0 md:tw-my-7 md:tw-border-b-0"
                                    >
                                        <div
                                            className="tw-relative tw-max-w-[60%] tw-leading-[18px] tw-pt-[7px] tw-top-0 tw-left-0 tw-text-sm tw-font-bold tw-opacity-40 md:tw-pt-0 md:tw-absolute md:tw-max-w-max md:tw-leading-[14px]">
                                            {rightItem.ingredientType && (
                                                <span>{rightItem.ingredientType}: </span>
                                            )}
                                            {rightItem.topText && <span>{rightItem.topText}</span>}
                                        </div>

                                        <div
                                            className="tw-relative tw-max-w-[60%] tw-leading-[18px] tw-bottom-0 tw-left-0 tw-flex md:tw-absolute md:tw-max-w-max md:tw-leading-[14px]">
                                            <div>{rightItem.bottomText}</div>
                                        </div>

                                        <div className="tw-group tw-absolute tw-h-[60px] tw-top-0 tw-right-0 hover:tw-cursor-pointer md:tw-relative md:tw-h-10">
                                            <div className="tw-hidden tw-opacity-0 tw-transition-opacity tw-duration-200 tw-ease-in-out group-hover:tw-opacity-100 md:tw-block">
                                                <div className="tw-absolute tw-inset-x-0 tw-top-1/2 tw-translate-y-[-1px] tw-border tw-border-solid tw-border-light-gray"/>
                                                {rightItem.tempAxis.map((axisItem, axisItemIndex) => (
                                                    <div
                                                        key={`${i}.${axisItemIndex}-temp-axis-marker`}
                                                        className="tw-absolute tw-text-xs tw-leading-[14px] tw-top-0 tw-ml-[-14px]"
                                                        style={axisItem.style}
                                                    >
                                                        {axisItem.text}
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="time-temp-line" style={{
                                                width: `${rightItem.tempLineWidth}%`
                                            }}/>
                                            <div
                                                className="tw-hidden tw-absolute tw-top-auto tw-bottom-0 tw-h-10 tw-rounded-full tw-bg-light-green tw-pr-10 tw-z-[2] md:tw-block md:tw-top-0"
                                                style={{
                                                    width: `${rightItem.tempBarWidth}%`,
                                                    left: `${rightItem.tempLineWidth}%`
                                                }}
                                            />
                                            <div
                                                className="time-temp-circle tw-border-2 tw-border-solid tw-border-white tw-bg-dark-green tw-z-[200] tw-right-[50px] before:tw-content-['We_like'] md:tw-right-0 md:before:tw-content-['']"
                                                style={{left: `${rightItem.tempLineWidth}%`}}
                                            >
                                                {rightItem.lowTempText}
                                            </div>
                                            <div
                                                className="time-temp-circle tw-right-0 tw-transform-none tw-bg-light-green before:tw-content-['Max'] tw-h-[38px] tw-w-[38px] tw-border tw-border-solid tw-border-white tw-z-[100] md:tw-translate-x-px md:tw-translate-y-px md:before:tw-content-['']"
                                                style={{left: `${rightItem.tempBarWidth + rightItem.tempLineWidth}%`}}
                                            >
                                                {rightItem.highTempText}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Fragment>
                    ))}
                    <div className="tw-flex tw-pt-7 tw-text-left tw-flex-wrap">
                        {item.description.map((desc, descIndex) => (
                            <div
                                key={`${i}.${descIndex}-desc`}
                                className="tw-grow tw-basis-full tw-pr-0 tw-box-border tw-mb-3.5 xs:tw-pr-7 sm:tw-basis-1/2 lg:tw-basis-1/3 xl:tw-mb-0">
                                <h6 className="tw-w-full tw-mb-[7px] tw-border-0 tw-border-b-2 tw-border-black tw-border-solid">
                                    {desc.heading}
                                </h6>
                                <p className="tw-mb-0">
                                    {desc.content}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                {((temperatureGuideData.length - 1) > i) && (
                    <hr className="tw-hidden md:tw-block md:tw-border-0 md:tw-border-b-2 md:tw-border-black md:tw-border-solid"/>
                )}
            </Fragment>
        ))}
    </div>
);

export default TemperatureGuide;