import React from 'react';
import ReactDOM from 'react-dom';

import './tailwind.css';
import {EggCartonDecoder, TemperatureGuide} from './widgets';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'development') {
    ReactDOM.render(<TemperatureGuide/>, document.getElementById('root'));
}

export function initEggCartonDecoder(params) {
    const {elementId} = params;
    if (document.getElementById(elementId)) {
        ReactDOM.render(<EggCartonDecoder/>, document.getElementById(elementId));
    }
}

export function initTemperatureGuide(params) {
    const {elementId} = params;
    if (document.getElementById(elementId)) {
        ReactDOM.render(<TemperatureGuide/>, document.getElementById(elementId));
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
