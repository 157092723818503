const TextInput = (
    {
        id,
        label,
        value = '',
        onChange = null,
        onBlur = null,
        ...rest
    }
) => {

    return (
        <div className="tw-block tw-text-left">
            <label htmlFor={id} className="tw-text-gray-700 tw-not-italic">{label}</label>
            <input
                className="tw-w-full tw-px-0.5 tw-py-2 tw-border-0 tw-border-b-2 tw-border-gray-300 focus:tw-ring-0 focus:tw-border-black focus-visible:tw-outline-0"
                id={id}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                {...rest}
            />
        </div>
    );
};

export default TextInput;