const Button = ({children, ...props}) => (
    <button
        className="tw-px-7 tw-py-1 tw-text-lg tw-text-white tw-font-bold
        tw-rounded-full tw-border-0 tw-bg-orange focus:tw-outline-0
        disabled:tw-bg-[#B8B6B4]"
        {...props}
    >
        {children}
    </button>
);

export default Button;