import {useState} from 'react';
import {addDays, differenceInDays, format, getYear, isSameDay, set} from 'date-fns';

import Logo from '../../assets/images/egg-carton-decoder-logo.svg';
import {Button, Carousel, TextInput} from '../../components';
import {PLANT_LIST, SUGGESTION_TEXT, SUGGESTION_TITLE} from '../../shared/utilities/referenceData';
import {getAddressString} from '../../shared/utilities/utility';
import {getRecipes} from '../../shared/services';
import {ENV, URL} from "../../shared/utilities/constant";

const EggCartonDecoder = () => {
    const [state, setState] = useState({
        plantCode: '',
        packDay: '',
        selectedPlant: null,
        packedDate: '',
        packDayDiff: -1,
        isSubmitted: false
    });
    const [recipes, setRecipes] = useState({});

    const isValidPlantCodeVal = () => !!state.packDay;

    const isValidPickDay = () => {
        let result = false;
        if (state.packDay !== '' && !isNaN(Number(state.packDay))) {
            const pickDayVal = Number(state.packDay);
            result = (pickDayVal >= 1) && (pickDayVal <= 365);
        }
        return result;
    };

    const handlePackDayBlur = (e) => {
        let packDay = e.target.value.trim();
        if (!isNaN(Number(packDay)) && packDay.length < 3 && packDay.length > 0) {
            packDay = (packDay.length === 1) ? `00${packDay}` : `0${packDay}`;
        }
        setState({...state, packDay});
    };

    const handleChange = (e) => setState({...state, [e.target.name]: e.target.value});

    const handleDecoderSearch = () => {
        const isSubmitted = state.isSubmitted;
        const plantCode = state.plantCode.trim().replace(/^\D+/, '').trim().toUpperCase()
        const plant = PLANT_LIST.find(item => plantCode === `${item.plantNo}`);
        const {packedDate, packDayDiff} = getPackedDayAndDiff();
        setState({
            ...state,
            isSubmitted: true,
            selectedPlant: plant ? plant : null,
            packedDate, packDayDiff
        });
        const recipesKey = packDayDiff <= 30 ? '30' : '45';
        if (!isSubmitted || !recipes.hasOwnProperty(recipesKey)) {
            getRecipes(packDayDiff).then(r => r.json()).then(data => {
                setRecipes({...recipes, [recipesKey]: data});
            }).catch(e => console.error(e));
        }
    };

    const getPackedDayAndDiff = () => {
        const today = set(new Date(), {hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
        const currentYear = getYear(today);
        let packedDate = addDays(new Date(currentYear, 0, 1), Number(state.packDay) - 1);
        if (!isSameDay(packedDate, today) && packedDate > today) {
            packedDate = addDays(new Date(currentYear - 1, 0, 1), Number(state.packDay) - 1);
        }
        return {
            packedDate: format(packedDate, 'MMMM d, yyyy'),
            packDayDiff: differenceInDays(today, packedDate)
        };
    };

    const getSuggestionText = () => {
        if (state.packDayDiff >= 0 && state.packDayDiff <= 30) {
            return SUGGESTION_TEXT.TILL_30;
        } else if (state.packDayDiff > 30 && state.packDayDiff <= 45) {
            return SUGGESTION_TEXT.TILL_45;
        } else if (state.packDayDiff > 45 && state.packDayDiff <= 70) {
            return SUGGESTION_TEXT.TILL_70;
        } else if (state.packDayDiff > 70) {
            return SUGGESTION_TEXT.MORE_THAN_70;
        }
    };

    const getSuggestionTitle = () => {
        if (state.packDayDiff >= 0 && state.packDayDiff <= 30) {
            return SUGGESTION_TITLE.TILL_30;
        } else if (state.packDayDiff > 30 && state.packDayDiff <= 45) {
            return SUGGESTION_TITLE.TILL_45;
        } else if (state.packDayDiff > 45 && state.packDayDiff <= 70) {
            return SUGGESTION_TITLE.TILL_70;
        } else if (state.packDayDiff > 70) {
            return SUGGESTION_TITLE.MORE_THAN_70;
        }
    };

    const handleAddressClick = () => {
        const addressString = getAddressString(state.selectedPlant);
        window.open(`https://maps.google.com/?q=${addressString}/`, '_blank');
    };

    const handleRecipeClick = (recipeId) => {
        const recipeLink = `/activities/${recipeId}`;
        window.open(recipeLink, '_blank');
    };

    const renderRecipes = () => {
        let recipeList = [];
        if (state.packDayDiff <= 30 && !!recipes['30']) {
            recipeList = recipes['30'];
        } else if (state.packDayDiff > 30 && !!recipes['45']) {
            recipeList = recipes['45'];
        }
        return recipeList.map(item => (
            <div
                key={item.slug}
                data-testid="suggested-recipe"
                className={"tw-h-full tw-w-60 tw-cursor-pointer tw-flex-none tw-overflow-hidden"}
                onClick={() => handleRecipeClick(item.slug)}
            >
                <div
                    className={"tw-h-60 tw-w-60 tw-bg-cover tw-rounded-md tw-border tw-mb-4"}
                    style={{backgroundImage: `url("${item.image}")`}}
                />
                <p className="tw-font-medium tw-text-lg tw-text-left tw-not-italic">
                    {item.title}
                </p>
            </div>
        ));
    };

    return (
        <div className="tw-container tw-max-w-sm tw-mx-auto tw-my-10 tw-flex tw-justify-center tw-flex-col tw-bg-transparent">
            <div className="tw-flex tw-mb-8">
                <img
                    src={`${URL[ENV].BASE}/static/media/egg-carton-decoder-logo.svg`}
                    style={{height: 180, width: 'auto', transform: 'translateX(0px)'}}
                />
                <h1 className="tw-text-5xl tw-font-bold tw-ml-2 tw-text-left tw-not-italic">
                    The ChefSteps Egg Carton Decoder
                </h1>
            </div>

            <div className="tw-grid tw-grid-cols-2 tw-gap-8 tw-mb-8">
                <TextInput
                    id="plantCode"
                    name="plantCode"
                    label="Plant code"
                    placeholder="Enter plant code"
                    value={state.plantCode}
                    onChange={handleChange}
                />

                <TextInput
                    id="packDay"
                    name="packDay"
                    label="Pack date"
                    placeholder="001"
                    value={state.packDay}
                    onChange={handleChange}
                    onBlur={handlePackDayBlur}
                />
            </div>

            <div className="tw-flex tw-mb-8 tw-justify-center">
                <Button
                    type="submit"
                    disabled={!isValidPlantCodeVal() || !isValidPickDay()}
                    onClick={handleDecoderSearch}
                >
                    {state.isSubmitted ? "Decode Again" : "Decode"}
                </Button>
            </div>

            {state.isSubmitted && <div className="tw-flex tw-mb-8 tw-flex-col">
                <h1 className="tw-text-3xl tw-font-bold tw-mb-2 tw-text-left tw-not-italic">
                    These eggs were packed {state.packDayDiff} days ago
                </h1>
                <span className="tw-mb-8 tw-text-left tw-not-italic">
                    on {state.packedDate}.
                </span>

                {!state.selectedPlant ? (
                    <span className="tw-mb-8 tw-text-left tw-not-italic">
                        We can’t find a producer for that plant code.
                    </span>
                ) : (
                    <div className="tw-mb-8 tw-text-left tw-not-italic">
                        <p className="tw-text-left tw-not-italic">
                            The producer for code {state.selectedPlant.plantNo} is
                        </p>
                        <p className="tw-font-semibold tw-text-left tw-not-italic">
                            {state.selectedPlant.facilityName}
                        </p>

                        <p
                            data-testid="selected-plant-address"
                            className="tw-underline tw-underline-offset-2 tw-cursor-pointer tw-text-left tw-not-italic"
                            onClick={handleAddressClick}
                        >
                            {state.selectedPlant.addressLine1}<br/>
                            {state.selectedPlant.city}, {state.selectedPlant.state} {state.selectedPlant.zipCode}
                        </p>
                    </div>
                )}
                <p className="tw-mb-8 tw-text-left tw-not-italic">{getSuggestionText()}</p>
                <h1 className="tw-text-3xl tw-font-bold tw-mb-4 tw-text-left tw-not-italic">
                    {getSuggestionTitle()}
                </h1>

                <Carousel>{renderRecipes()}</Carousel>
            </div>}

            {!state.isSubmitted && <>
                <p className="tw-mb-8 tw-text-left">
                    <span className="tw-font-bold tw-not-italic">Plant code </span>
                    is the location where your eggs were packed, sometimes preceded by a “P”.
                </p>

                <p className="tw-text-left">
                    <span className="tw-font-bold tw-not-italic">Pack date </span>
                    is the day of the year the eggs were packed. Look for a 3-digit number from 001 to 365.
                </p>
            </>}
        </div>
    );
};

export default EggCartonDecoder;