export const SUGGESTION_TEXT = {
    TILL_30: "Eggs at peak freshness. Tight yolks and whites that hold their shape well during poaching and frying. Or make that mayo!",
    TILL_45: "Older eggs are best used for cooking all the way through. The whites whip quickly and easily but are not as stable when cooked.",
    TILL_70: "Pushing it, but should still be okay if they've been properly refrigerated.",
    MORE_THAN_70: "Whoa, these are pretty old. Might be time to toss 'em."
};

export const SUGGESTION_TITLE = {
    TILL_30: "Cook up some eggs.",
    TILL_45: "Put those eggs to work.",
    TILL_70: "Egg Science, Recipes, Cooking Tools & Tips",
    MORE_THAN_70: "Egg Science, Recipes, Cooking Tools & Tips"
}

export const PLANT_LIST = [
    {
        "plantNo": "1",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "704 Factory Street",
        "addressLine2": "",
        "city": "Wilkesboro",
        "state": "NC",
        "zipCode": "28697",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "3",
        "facilityName": "Mountaire Farms of Delaware, Inc.",
        "addressLine1": "Rt. #24 East",
        "addressLine2": "",
        "city": "Millsboro",
        "state": "DE",
        "zipCode": "19966",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "6",
        "facilityName": "Tyson Foods",
        "addressLine1": "67240 Main Street",
        "addressLine2": "",
        "city": "Blountsville",
        "state": "AL",
        "zipCode": "35031",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "18",
        "facilityName": "Cargill Turkey and Cooked Meats",
        "addressLine1": "135 Huffman Dr",
        "addressLine2": "",
        "city": "Dayton",
        "state": "VA",
        "zipCode": "22821",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "21",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "214  South Main Street",
        "addressLine2": "",
        "city": "Moorefield",
        "state": "WV",
        "zipCode": "26836",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "40",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "125 Industrial Blvd",
        "addressLine2": "",
        "city": "Ellijay",
        "state": "GA",
        "zipCode": "30540",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "72",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "1291 North Highway 7",
        "addressLine2": "",
        "city": "Dardanelle",
        "state": "AR",
        "zipCode": "72834",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "146",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "13264 Mountain Road",
        "addressLine2": "",
        "city": "Glen Allen",
        "state": "VA",
        "zipCode": "23060",
        "service": "Scheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "157",
        "facilityName": "Foster Turkey Products",
        "addressLine1": "500 F Street",
        "addressLine2": "",
        "city": "Turlock",
        "state": "CA",
        "zipCode": "95380",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "177",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "949 Industrial Blvd.",
        "addressLine2": "",
        "city": "Gainesville",
        "state": "GA",
        "zipCode": "30503",
        "service": "Scheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "184",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "1129 Old Middleton Road",
        "addressLine2": "",
        "city": "Elberton",
        "state": "GA",
        "zipCode": "30635",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "192",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "3500 Lake Guntersville Park Drive",
        "addressLine2": "",
        "city": "Guntersville",
        "state": "AL",
        "zipCode": "35976",
        "service": "Scheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "206",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "928 Martin Luther King Jr Blvd",
        "addressLine2": "",
        "city": "Nacogdoches",
        "state": "TX",
        "zipCode": "75961",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "208",
        "facilityName": "Georges Processing Inc",
        "addressLine1": "1306 North Kansas Street",
        "addressLine2": "",
        "city": "Springdale",
        "state": "AR",
        "zipCode": "72764",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "218",
        "facilityName": "Pilgrims Pride Corp",
        "addressLine1": "1710 W. Frank St.",
        "addressLine2": "",
        "city": "Lufkin",
        "state": "TX",
        "zipCode": "75901",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "220",
        "facilityName": "Zacky Farms LLC",
        "addressLine1": "1111 Navy Drive",
        "addressLine2": "",
        "city": "Stockton",
        "state": "CA",
        "zipCode": "95206",
        "service": "Unscheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "243",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "340 West Maple Street",
        "addressLine2": "",
        "city": "Cumming",
        "state": "GA",
        "zipCode": "30128",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "244",
        "facilityName": "Plainville Brands LLC",
        "addressLine1": "304 South Water Street",
        "addressLine2": "",
        "city": "New Oxford",
        "state": "PA",
        "zipCode": "17350",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "322",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "851 Sauk River Rd",
        "addressLine2": "",
        "city": "Cold Spring",
        "state": "MN",
        "zipCode": "",
        "service": "Scheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "325",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "1019 Shelbyville Street",
        "addressLine2": "",
        "city": "Center",
        "state": "TX",
        "zipCode": "75935",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "403",
        "facilityName": "West Central Turkeys, Inc.",
        "addressLine1": "704 North Broadway",
        "addressLine2": "Purchase Order #4345495",
        "city": "Pelican Rapids",
        "state": "MN",
        "zipCode": "56572",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "419",
        "facilityName": "Case Farms",
        "addressLine1": "121 Rand Street",
        "addressLine2": "",
        "city": "Morganton",
        "state": "NC",
        "zipCode": "28655",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "420",
        "facilityName": "Michael Foods Inc.",
        "addressLine1": "105 N. Main St",
        "addressLine2": "",
        "city": "Wakefield",
        "state": "NE",
        "zipCode": "68784",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "425",
        "facilityName": "Northern Pride, Inc.",
        "addressLine1": "518 Pennington Ave South",
        "addressLine2": "",
        "city": "Thief River Falls",
        "state": "MN",
        "zipCode": "56701",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "454",
        "facilityName": "DeWeerdt Poultry",
        "addressLine1": "3991 50th Street",
        "addressLine2": "",
        "city": "Holland",
        "state": "MI",
        "zipCode": "49423",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "460",
        "facilityName": "Jaindl Turkey Sales,  Inc",
        "addressLine1": "3150 Coffeetown Road",
        "addressLine2": "",
        "city": "Orefield",
        "state": "PA",
        "zipCode": "18069",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "467",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "1255 Stolte Rd",
        "addressLine2": "",
        "city": "Marion",
        "state": "TX",
        "zipCode": "78124",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "476",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "616 Kingsbridge Road",
        "addressLine2": "",
        "city": "Carrollton",
        "state": "GA",
        "zipCode": "30117",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "481",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "671 Randall Wobbe Lane",
        "addressLine2": "",
        "city": "Springdale",
        "state": "AR",
        "zipCode": "72764",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "510",
        "facilityName": "House of Raeford Farms Inc",
        "addressLine1": "3333 Hwy 117 South",
        "addressLine2": "",
        "city": "Rose Hill",
        "state": "NC",
        "zipCode": "28458",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "511",
        "facilityName": "Butterball LLC",
        "addressLine1": "307 Dodgen Place",
        "addressLine2": "",
        "city": "Ozark",
        "state": "AR",
        "zipCode": "72949",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "519",
        "facilityName": "Amick Farms LLC",
        "addressLine1": "525 Wayne Drive",
        "addressLine2": "",
        "city": "Laurel",
        "state": "MS",
        "zipCode": "39440",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "544",
        "facilityName": "Jennie O Turkey Store",
        "addressLine1": "1129 E Main Street",
        "addressLine2": "",
        "city": "Melrose",
        "state": "MN",
        "zipCode": "56352",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "548",
        "facilityName": "JCG Foods AL LLC",
        "addressLine1": "764 George Cagle Dr",
        "addressLine2": "",
        "city": "Collinsville",
        "state": "AL",
        "zipCode": "35961",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "551",
        "facilityName": "Jennie-O Turkey Store",
        "addressLine1": "2505 Wilmar Avenue SW",
        "addressLine2": "",
        "city": "Willmar",
        "state": "MN",
        "zipCode": "56201",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "607",
        "facilityName": "Tyson Foods, Inc",
        "addressLine1": "212 East Elm",
        "addressLine2": "",
        "city": "Rogers",
        "state": "AR",
        "zipCode": "72757",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "608",
        "facilityName": "Gemperle Enterprises, Schendel Ranch",
        "addressLine1": "17265 Schendel Road",
        "addressLine2": "",
        "city": "Delhi",
        "state": "CA",
        "zipCode": "95315",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "622",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "2023 Hasty Street",
        "addressLine2": "",
        "city": "Monroe",
        "state": "NC",
        "zipCode": "28111",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "628",
        "facilityName": "Reedsburg Egg Company Inc",
        "addressLine1": "607 Railroad Street",
        "addressLine2": "",
        "city": "Reedsburg",
        "state": "WI",
        "zipCode": "53959",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "635",
        "facilityName": "Cargill Foods - Waco TX",
        "addressLine1": "2510 East Lake Shore Drive",
        "addressLine2": "",
        "city": "Waco",
        "state": "TX",
        "zipCode": "76705",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "667",
        "facilityName": "Mountaire Farms of Delmarva, Inc.",
        "addressLine1": "55 Hosier St",
        "addressLine2": "",
        "city": "Selbyville",
        "state": "DE",
        "zipCode": "19975",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "705",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "401 South Third St",
        "addressLine2": "",
        "city": "De Queen",
        "state": "AR",
        "zipCode": "71832",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "737",
        "facilityName": "House of Raeford Farms Inc",
        "addressLine1": "253 Butterball Rd",
        "addressLine2": "",
        "city": "Teachey",
        "state": "NC",
        "zipCode": "28464",
        "service": "Unscheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "758",
        "facilityName": "Tyson Foods",
        "addressLine1": "3865 Highway 35 North",
        "addressLine2": "",
        "city": "Carthage",
        "state": "MS",
        "zipCode": "39051",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "764",
        "facilityName": "Perdue Foods, Inc.",
        "addressLine1": "521 Willow St.",
        "addressLine2": "",
        "city": "Salisbury",
        "state": "MD",
        "zipCode": "21802",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "768",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "442 Plant St.",
        "addressLine2": "",
        "city": "Waldron",
        "state": "AR",
        "zipCode": "72958",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "782",
        "facilityName": "Germantown Egg Company",
        "addressLine1": "8105 Wesclin Road",
        "addressLine2": "",
        "city": "Germantown",
        "state": "IL",
        "zipCode": "62245",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "806",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "11224 Lankford Hwy",
        "addressLine2": "",
        "city": "Temperanceville",
        "state": "VA",
        "zipCode": "23442",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "810",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "129 Potomac Ave",
        "addressLine2": "",
        "city": "Moorefield",
        "state": "WV",
        "zipCode": "26836",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "863",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "625 Avenue K",
        "addressLine2": "",
        "city": "Chase",
        "state": "KS",
        "zipCode": "67524",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "894",
        "facilityName": "Hillshire Brands dba Tyson Foods",
        "addressLine1": "3900 Meacham Blvd.",
        "addressLine2": "",
        "city": "Haltom City",
        "state": "TX",
        "zipCode": "76117",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "935",
        "facilityName": "Allen Harim Foods, LLC.",
        "addressLine1": "18752 Harbeson Road",
        "addressLine2": "",
        "city": "Harbeson",
        "state": "DE",
        "zipCode": "19951",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "937",
        "facilityName": "Cal-Maine Foods, Inc.",
        "addressLine1": "197 Cal-Maine Lane",
        "addressLine2": "",
        "city": "Searcy",
        "state": "AR",
        "zipCode": "72143",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "961",
        "facilityName": "Cargill, Inc.",
        "addressLine1": "1001 Smith Street",
        "addressLine2": "",
        "city": "California",
        "state": "MO",
        "zipCode": "65018",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "963",
        "facilityName": "Cargill Meat Solutions",
        "addressLine1": "730 East Randall Wobbe Lane",
        "addressLine2": "",
        "city": "Springdale",
        "state": "AR",
        "zipCode": "72764",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "1008",
        "facilityName": "Sunnyside Farms",
        "addressLine1": "2378 West 1300 North",
        "addressLine2": "",
        "city": "North Manchester",
        "state": "IN",
        "zipCode": "46962",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1017",
        "facilityName": "Hillandale- Gettysburg, L.P.",
        "addressLine1": "370 Spicer Road",
        "addressLine2": "",
        "city": "Gettysburg",
        "state": "PA",
        "zipCode": "17325",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1020",
        "facilityName": "Cal-Maine Foods, Inc",
        "addressLine1": "22600 Hazen Road",
        "addressLine2": "",
        "city": "Robertsdale",
        "state": "AL",
        "zipCode": "36567",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1025",
        "facilityName": "Cal-Maine Foods",
        "addressLine1": "3987 Porter Road",
        "addressLine2": "",
        "city": "Bethune",
        "state": "SC",
        "zipCode": "29009",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1030",
        "facilityName": "Hi-Grade Egg Producers & Processors",
        "addressLine1": "5780 East 100 North, Lot 18",
        "addressLine2": "",
        "city": "North Manchester",
        "state": "IN",
        "zipCode": "46962",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1034",
        "facilityName": "Country Charm Eggs, LLC",
        "addressLine1": "280 Cargill Drive",
        "addressLine2": "",
        "city": "Gillsville",
        "state": "GA",
        "zipCode": "30543",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1038",
        "facilityName": "National Pasteurized Egg",
        "addressLine1": "2963 Bernice Road",
        "addressLine2": "",
        "city": "Lansing",
        "state": "IL",
        "zipCode": "60438",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1044",
        "facilityName": "Hudson Egg Farms",
        "addressLine1": "1812 Route 5 East",
        "addressLine2": "",
        "city": "Elbridge",
        "state": "NY",
        "zipCode": "13060",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1047",
        "facilityName": "R. W. Sauder Inc",
        "addressLine1": "1824 William Penn Way",
        "addressLine2": "",
        "city": "Lancaster",
        "state": "PA",
        "zipCode": "17601",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1049",
        "facilityName": "Pitman Farms",
        "addressLine1": "350 South 300 West",
        "addressLine2": "",
        "city": "Moroni",
        "state": "UT",
        "zipCode": "84646",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "1050",
        "facilityName": "Cal-Maine Farms, Inc.",
        "addressLine1": "Hwy 467 South",
        "addressLine2": "",
        "city": "Edwards",
        "state": "MS",
        "zipCode": "39066",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1051",
        "facilityName": "St Partners LLC dba Almark Foods",
        "addressLine1": "2118 Centennial Drive",
        "addressLine2": "",
        "city": "Gainesville",
        "state": "GA",
        "zipCode": "30504",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1059",
        "facilityName": "Nest Fresh",
        "addressLine1": "925 Saint Andrew Street",
        "addressLine2": "",
        "city": "Gonzales",
        "state": "TX",
        "zipCode": "78629",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1065",
        "facilityName": "Hyde County Egg Farm",
        "addressLine1": "1560 Hyde Park Canal Road",
        "addressLine2": "",
        "city": "Pantego",
        "state": "NC",
        "zipCode": "27860",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1066",
        "facilityName": "Nature Pure LLC",
        "addressLine1": "26560 Storms Road",
        "addressLine2": "",
        "city": "West Mansfield",
        "state": "OH",
        "zipCode": "43358",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1067",
        "facilityName": "Nature Pure LLC",
        "addressLine1": "25275 State Route 347",
        "addressLine2": "",
        "city": "Raymond",
        "state": "OH",
        "zipCode": "43067",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1069",
        "facilityName": "Hillandale- Gettysburg, L.P.",
        "addressLine1": "3710 Oxford Road",
        "addressLine2": "",
        "city": "Gettysburg",
        "state": "PA",
        "zipCode": "17325",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1078",
        "facilityName": "Cal-Maine Foods, Inc.",
        "addressLine1": "263 Cal-Maine Road",
        "addressLine2": "",
        "city": "Pine Grove",
        "state": "LA",
        "zipCode": "70453",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1080",
        "facilityName": "Forsman Farms",
        "addressLine1": "1200 Commerce Blvd",
        "addressLine2": "",
        "city": "Howard Lake",
        "state": "MN",
        "zipCode": "55349",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1082",
        "facilityName": "Pearl Valley Eggs",
        "addressLine1": "968 South Kent Road",
        "addressLine2": "",
        "city": "Pearl City",
        "state": "IL",
        "zipCode": "61062",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1084",
        "facilityName": "Cal-Maine Foods",
        "addressLine1": "410 FM 3333",
        "addressLine2": "",
        "city": "Farwell",
        "state": "TX",
        "zipCode": "79325",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1087",
        "facilityName": "National Pasteurized Eggs, Inc.",
        "addressLine1": "22252 SD Hwy 13",
        "addressLine2": "",
        "city": "Flandreau",
        "state": "SD",
        "zipCode": "57028",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1088",
        "facilityName": "Cold Spring Egg Farm",
        "addressLine1": "W2024 Hwy 59",
        "addressLine2": "",
        "city": "Palmyra",
        "state": "WI",
        "zipCode": "53156",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1089",
        "facilityName": "Heartland Quality Egg Farm",
        "addressLine1": "9800 County Road 26",
        "addressLine2": "",
        "city": "West Mansfield",
        "state": "OH",
        "zipCode": "43358",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1091",
        "facilityName": "Dwight Bell Plant",
        "addressLine1": "4241 Dwight Way",
        "addressLine2": "",
        "city": "Atwater",
        "state": "CA",
        "zipCode": "95301",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1098",
        "facilityName": "Sunrise Farms Inc",
        "addressLine1": "2060 White Avenue",
        "addressLine2": "",
        "city": "Harris",
        "state": "IA",
        "zipCode": "51345",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1100",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "1185 6th Road",
        "addressLine2": "",
        "city": "Chase",
        "state": "KS",
        "zipCode": "67524",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1104",
        "facilityName": "Egg Innovations",
        "addressLine1": "4799 W 100 N",
        "addressLine2": "",
        "city": "Warsaw",
        "state": "IN",
        "zipCode": "46580",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1107",
        "facilityName": "Noah W Kreider & Sons",
        "addressLine1": "301 Longview Drive",
        "addressLine2": "",
        "city": "Middletown",
        "state": "PA",
        "zipCode": "17057",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1111",
        "facilityName": "Oakdell Egg Farms",
        "addressLine1": "1831 East Sagemoor Rd.",
        "addressLine2": "",
        "city": "Pasco",
        "state": "WA",
        "zipCode": "99301",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1112",
        "facilityName": "Oakdell Egg Farms",
        "addressLine1": "7401 Glade North Road",
        "addressLine2": "",
        "city": "Pasco",
        "state": "WA",
        "zipCode": "98837",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1115",
        "facilityName": "Rigtrup Poultry Farms",
        "addressLine1": "17122 South Tunnel Road",
        "addressLine2": "",
        "city": "Elberta",
        "state": "UT",
        "zipCode": "84626",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1118",
        "facilityName": "Schipper Eggs, LLC",
        "addressLine1": "4852 142nd Ave",
        "addressLine2": "",
        "city": "Holland",
        "state": "MI",
        "zipCode": "49423",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1124",
        "facilityName": "Hillandale-Gettysburg, LP",
        "addressLine1": "3913 Oxford Rd.",
        "addressLine2": "",
        "city": "New Oxford",
        "state": "PA",
        "zipCode": "17350",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1125",
        "facilityName": "Hillandale-Gettysburg, L.P.",
        "addressLine1": "3710 Oxford Road",
        "addressLine2": "",
        "city": "Gettysburg",
        "state": "PA",
        "zipCode": "17325",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1126",
        "facilityName": "Shepherds Poultry Farms LLC",
        "addressLine1": "3502 W 6400 South",
        "addressLine2": "",
        "city": "Spanish Fork",
        "state": "UT",
        "zipCode": "84660",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1128",
        "facilityName": "C.B. Nichols Egg Ranch, Inc",
        "addressLine1": "331 West Citrus Avenue",
        "addressLine2": "",
        "city": "Colton",
        "state": "CA",
        "zipCode": "92324",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1129",
        "facilityName": "Hillandale Farms East",
        "addressLine1": "2862 Daron Road",
        "addressLine2": "",
        "city": "Spring Grove",
        "state": "PA",
        "zipCode": "17362",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1136",
        "facilityName": "Demler Brothers LLC",
        "addressLine1": "25818 Highway 78",
        "addressLine2": "",
        "city": "Ramona",
        "state": "CA",
        "zipCode": "92065",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1143",
        "facilityName": "National Foods NW LLC",
        "addressLine1": "2005 268th St NW",
        "addressLine2": "",
        "city": "Stanwood",
        "state": "WA",
        "zipCode": "98292",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1150",
        "facilityName": "Canon Egg Farm",
        "addressLine1": "4661 Airline-Goldmine Rd.",
        "addressLine2": "",
        "city": "Canon",
        "state": "GA",
        "zipCode": "30520",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1153",
        "facilityName": "R. W. Sauder Inc",
        "addressLine1": "570 Furnace Hills Pike",
        "addressLine2": "",
        "city": "Lititz",
        "state": "PA",
        "zipCode": "17543",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1160",
        "facilityName": "Centrum Valley Farms Dows",
        "addressLine1": "2853 310th Street",
        "addressLine2": "",
        "city": "Dows",
        "state": "IA",
        "zipCode": "50071",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1164",
        "facilityName": "Hillandale Farms LLC",
        "addressLine1": "13706 230th Street",
        "addressLine2": "",
        "city": "West Union",
        "state": "IA",
        "zipCode": "52175",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1167",
        "facilityName": "Sparboe Farms Inc",
        "addressLine1": "6339 WCR 47",
        "addressLine2": "",
        "city": "Hudson",
        "state": "CO",
        "zipCode": "80642",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1168",
        "facilityName": "Cal-Maine Farms, Inc.",
        "addressLine1": "1063 Zumbrum Road",
        "addressLine2": "",
        "city": "Union City",
        "state": "OH",
        "zipCode": "45390",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1169",
        "facilityName": "Cooper Farms Central Egg Processing",
        "addressLine1": "3763 Philothea Road",
        "addressLine2": "",
        "city": "Coldwater",
        "state": "OH",
        "zipCode": "45828",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1170",
        "facilityName": "Centrum Valley Farms North",
        "addressLine1": "2652 250th Street",
        "addressLine2": "",
        "city": "Clarion",
        "state": "IA",
        "zipCode": "50525",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1171",
        "facilityName": "Wilcox Montana Division",
        "addressLine1": "1015 N. 38th Street",
        "addressLine2": "",
        "city": "Great Falls",
        "state": "MT",
        "zipCode": "59404",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1173",
        "facilityName": "Sparboe Farms Inc",
        "addressLine1": "65018 Hwy 12",
        "addressLine2": "",
        "city": "Litchfield",
        "state": "MN",
        "zipCode": "55355",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1174",
        "facilityName": "RCF, LLC dba Gemstone Foods",
        "addressLine1": "641 Holly St NE",
        "addressLine2": "",
        "city": "Decatur",
        "state": "AL",
        "zipCode": "35601",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1178",
        "facilityName": "ISE America Inc",
        "addressLine1": "795 Wards Hill Road",
        "addressLine2": "",
        "city": "Warwick",
        "state": "MD",
        "zipCode": "21912",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1179",
        "facilityName": "Luberski Inc dba Nest Fresh Eggs",
        "addressLine1": "4340 Glencoe Street",
        "addressLine2": "",
        "city": "Denver",
        "state": "CO",
        "zipCode": "80216",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1180",
        "facilityName": "Centrum Valley Farms South",
        "addressLine1": "2688 270th Street West",
        "addressLine2": "",
        "city": "Clarion",
        "state": "IA",
        "zipCode": "50101",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1185",
        "facilityName": "Weaver Bros., Inc.",
        "addressLine1": "895 E. Main Street",
        "addressLine2": "",
        "city": "Versailles",
        "state": "OH",
        "zipCode": "45380",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1186",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "180 North Church Street",
        "addressLine2": "",
        "city": "Blackshear",
        "state": "GA",
        "zipCode": "31516",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1187",
        "facilityName": "Hoosier Pride Farms LLC",
        "addressLine1": "7188 N. State Line Road",
        "addressLine2": "",
        "city": "Bryant",
        "state": "IN",
        "zipCode": "47326",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1189",
        "facilityName": "Winterset Egg Farm",
        "addressLine1": "1981 Highway 92",
        "addressLine2": "",
        "city": "Winterset",
        "state": "IA",
        "zipCode": "50273",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1190",
        "facilityName": "Centrum Valley Farms West",
        "addressLine1": "2550 270th Street",
        "addressLine2": "",
        "city": "Clarion",
        "state": "IA",
        "zipCode": "50525",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1194",
        "facilityName": "Carolina  Egg Company, Inc.",
        "addressLine1": "10927 Cooper Road",
        "addressLine2": "",
        "city": "Nashville",
        "state": "NC",
        "zipCode": "27856",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1196",
        "facilityName": "Daybreak Foods Inc",
        "addressLine1": "N5344 Crossman Road",
        "addressLine2": "",
        "city": "Lake Mills",
        "state": "WI",
        "zipCode": "53551",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1198",
        "facilityName": "Hillandale Farms Conn LLC",
        "addressLine1": "400 Mack Road",
        "addressLine2": "",
        "city": "Lebanon",
        "state": "CT",
        "zipCode": "6249",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1199",
        "facilityName": "Daybreak Foods Central Iowa Vincent",
        "addressLine1": "3159 Buchanan Ave",
        "addressLine2": "",
        "city": "Eagle Grove",
        "state": "IA",
        "zipCode": "50533",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1200",
        "facilityName": "Centrum Valley Farms East",
        "addressLine1": "2731 265th Street",
        "addressLine2": "",
        "city": "Galt",
        "state": "IA",
        "zipCode": "50101",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1201",
        "facilityName": "Pilgrims",
        "addressLine1": "484 Zimmerman Road",
        "addressLine2": "",
        "city": "Sanford",
        "state": "NC",
        "zipCode": "27330",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1206",
        "facilityName": "ISE America, Inc dba Red Bird Egg Farm LLC",
        "addressLine1": "33265 Walnut Tree Road",
        "addressLine2": "",
        "city": "Millington",
        "state": "MD",
        "zipCode": "21651",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1217",
        "facilityName": "Noah W Kreider & Sons",
        "addressLine1": "1145 Colebrook Road",
        "addressLine2": "",
        "city": "Mount Joy",
        "state": "PA",
        "zipCode": "17552",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1219",
        "facilityName": "Guthrie Center Egg Farm",
        "addressLine1": "2143 215th Road",
        "addressLine2": "",
        "city": "Guthrie Center",
        "state": "IA",
        "zipCode": "50115",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1224",
        "facilityName": "Esbenshade Farm",
        "addressLine1": "220 Eby Chiques Road",
        "addressLine2": "",
        "city": "Mount Joy",
        "state": "PA",
        "zipCode": "17552",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1226",
        "facilityName": "National Pasteurized Eggs",
        "addressLine1": "2865 310th Street",
        "addressLine2": "",
        "city": "Dows",
        "state": "IA",
        "zipCode": "50071",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1227",
        "facilityName": "Demler Farms LLC",
        "addressLine1": "1455 N. Warren Road",
        "addressLine2": "",
        "city": "San Jacinto",
        "state": "CA",
        "zipCode": "92582",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1239",
        "facilityName": "Riverview Farms Inc",
        "addressLine1": "1916 W 300 N",
        "addressLine2": "",
        "city": "Washington",
        "state": "IN",
        "zipCode": "47501",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1243",
        "facilityName": "Perdue Farms Inc",
        "addressLine1": "416 South Long Drive",
        "addressLine2": "",
        "city": "Rockingham",
        "state": "NC",
        "zipCode": "28380",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1244",
        "facilityName": "Eggs Hawaii, Inc.",
        "addressLine1": "1590 Hau Street",
        "addressLine2": "",
        "city": "Honolulu",
        "state": "HI",
        "zipCode": "96817",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1245",
        "facilityName": "Better Baked Foods LLC",
        "addressLine1": "2200 East 38th Street",
        "addressLine2": "",
        "city": "Erie",
        "state": "PA",
        "zipCode": "16510",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "1247",
        "facilityName": "Pete and Gerrys Organic Eggs LLC",
        "addressLine1": "104 Commerce Avenue",
        "addressLine2": "",
        "city": "Greencastle",
        "state": "PA",
        "zipCode": "17225",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1248",
        "facilityName": "Ritewood Egg",
        "addressLine1": "3643 S. 4000 E",
        "addressLine2": "",
        "city": "Franklin",
        "state": "ID",
        "zipCode": "83237",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1249",
        "facilityName": "George's",
        "addressLine1": "19992 Senedo Road",
        "addressLine2": "",
        "city": "Edinburg",
        "state": "VA",
        "zipCode": "22824",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1250",
        "facilityName": "Fieldale Farms Corporation",
        "addressLine1": "2115 Industrial Blvd",
        "addressLine2": "",
        "city": "Cornelia",
        "state": "GA",
        "zipCode": "30531",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1251",
        "facilityName": "Hillandale Farms Ohio LLC - Hicksville",
        "addressLine1": "9715 Rosedale Road",
        "addressLine2": "",
        "city": "Hicksville",
        "state": "OH",
        "zipCode": "43526",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1253",
        "facilityName": "Cort Acres Egg Farm",
        "addressLine1": "4887 E. Co. Rd. 800 N",
        "addressLine2": "",
        "city": "Seymour",
        "state": "IN",
        "zipCode": "47274",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1255",
        "facilityName": "Mid-States Specialty Eggs",
        "addressLine1": "30911 Hwy HH",
        "addressLine2": "",
        "city": "Smithton",
        "state": "MO",
        "zipCode": "65350",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1257",
        "facilityName": "Fieldale Farms Corporation",
        "addressLine1": "5163 Thompson Bridge Road",
        "addressLine2": "",
        "city": "Murrayville",
        "state": "GA",
        "zipCode": "30564",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1258",
        "facilityName": "Esbenshade Farms",
        "addressLine1": "2036 Mt. Pleasant Road",
        "addressLine2": "",
        "city": "Mt. Joy",
        "state": "PA",
        "zipCode": "17552",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1259",
        "facilityName": "Farmers Hen House",
        "addressLine1": "1956 520th Street SW",
        "addressLine2": "",
        "city": "Kalona",
        "state": "IA",
        "zipCode": "52247",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1260",
        "facilityName": "Skylane Farms",
        "addressLine1": "8539 Crosby Road NE",
        "addressLine2": "",
        "city": "Woodburn",
        "state": "OR",
        "zipCode": "97071",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1261",
        "facilityName": "Alderfer Poultry Farm Inc.",
        "addressLine1": "4460 Township Line Road",
        "addressLine2": "",
        "city": "Schwenksville",
        "state": "PA",
        "zipCode": "19743",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1263",
        "facilityName": "Noah W Kreider & Sons",
        "addressLine1": "451 Indian Village Road",
        "addressLine2": "",
        "city": "Manheim",
        "state": "PA",
        "zipCode": "17545",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1266",
        "facilityName": "Nest Fresh",
        "addressLine1": "31350 S. Hwy 170",
        "addressLine2": "",
        "city": "Canby",
        "state": "OR",
        "zipCode": "97013",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1267",
        "facilityName": "Mid-States Specialty Eggs",
        "addressLine1": "1302 Primrose Drive",
        "addressLine2": "",
        "city": "Berryville",
        "state": "AR",
        "zipCode": "72616",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1271",
        "facilityName": "Nest Fresh Pennsylvania",
        "addressLine1": "1226 Kauffman Road West",
        "addressLine2": "",
        "city": "Greencastle",
        "state": "PA",
        "zipCode": "17225",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1273",
        "facilityName": "Mid-States Specialty Eggs of Middlebury",
        "addressLine1": "13659 CR 20",
        "addressLine2": "",
        "city": "Middlebury",
        "state": "IN",
        "zipCode": "46540",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1281",
        "facilityName": "Daybreak Foods, Inc.",
        "addressLine1": "9998 N. 16000 E Road",
        "addressLine2": "",
        "city": "Grant Park",
        "state": "IL",
        "zipCode": "60940",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1282",
        "facilityName": "King Farms LLC",
        "addressLine1": "50 Parkside Inn Road",
        "addressLine2": "",
        "city": "Bernville",
        "state": "PA",
        "zipCode": "19506",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1284",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "654 Univeter Road",
        "addressLine2": "",
        "city": "Canton",
        "state": "GA",
        "zipCode": "30115",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1286",
        "facilityName": "Cal Maine Foods-Red River Valley Egg Farm",
        "addressLine1": "1400 Josef Hausler Memorial Way",
        "addressLine2": "",
        "city": "Bogata",
        "state": "TX",
        "zipCode": "75417",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1292",
        "facilityName": "Hilmar Processing LLC",
        "addressLine1": "7406 Griffith Road",
        "addressLine2": "",
        "city": "Hilmar",
        "state": "CA",
        "zipCode": "95324",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1293",
        "facilityName": "Dutch Country Organics",
        "addressLine1": "407 N Main",
        "addressLine2": "",
        "city": "Middlebury",
        "state": "IN",
        "zipCode": "46540",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1294",
        "facilityName": "Lone Cactus",
        "addressLine1": "Mile Marker 35, Highway 72",
        "addressLine2": "",
        "city": "Bouse",
        "state": "AZ",
        "zipCode": "85325",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1296",
        "facilityName": "R.W. Sauder, Inc",
        "addressLine1": "3986 Hampstead-Mexico Rd.",
        "addressLine2": "",
        "city": "Hampstead",
        "state": "MD",
        "zipCode": "21074",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1306",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "9565 County Road 476B",
        "addressLine2": "",
        "city": "Bushnell",
        "state": "FL",
        "zipCode": "33513",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1308",
        "facilityName": "Cal-Maine Foods",
        "addressLine1": "1974 County Road 283 West",
        "addressLine2": "",
        "city": "Harwood",
        "state": "TX",
        "zipCode": "78632",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1310",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "247 NW Hillandale Glen",
        "addressLine2": "",
        "city": "Lake City",
        "state": "FL",
        "zipCode": "32056",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1312",
        "facilityName": "Central Valley Eggs LLC",
        "addressLine1": "13606 Gun Club Road",
        "addressLine2": "",
        "city": "Wasco",
        "state": "CA",
        "zipCode": "93280",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1317",
        "facilityName": "Wayne Farms LLC",
        "addressLine1": "700 McDonald Avenue",
        "addressLine2": "",
        "city": "Albertville",
        "state": "AL",
        "zipCode": "35950",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1318",
        "facilityName": "Perdue Farms, Inc.",
        "addressLine1": "255 N. Rehobeth Blvd.",
        "addressLine2": "",
        "city": "Milford",
        "state": "DE",
        "zipCode": "19963",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1322",
        "facilityName": "Willamette Egg Farms - Moses Lake",
        "addressLine1": "12406 Road 7 NE",
        "addressLine2": "",
        "city": "Moses Lake",
        "state": "WA",
        "zipCode": "98837",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1325",
        "facilityName": "Tyson Foods Inc",
        "addressLine1": "403 S Custer Road",
        "addressLine2": "",
        "city": "New Holland",
        "state": "PA",
        "zipCode": "17557",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1326",
        "facilityName": "Hi-Grade Egg Producers",
        "addressLine1": "317 E. 360 North Route 45",
        "addressLine2": "",
        "city": "Loda",
        "state": "IL",
        "zipCode": "60948",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1329",
        "facilityName": "White County Egg Farm",
        "addressLine1": "5408 W. State Rd. 16",
        "addressLine2": "",
        "city": "Monon",
        "state": "IN",
        "zipCode": "47959",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1330",
        "facilityName": "Cal-Maine Foods, Inc.",
        "addressLine1": "220 Southern Empire Road",
        "addressLine2": "",
        "city": "Shady Dale",
        "state": "GA",
        "zipCode": "31085",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1332",
        "facilityName": "Automated Egg",
        "addressLine1": "7165 N. 500 E",
        "addressLine2": "",
        "city": "North Manchester",
        "state": "IN",
        "zipCode": "46962",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1335",
        "facilityName": "Hillandale Farms Conn LLC",
        "addressLine1": "28 Under the Mountain Road",
        "addressLine2": "",
        "city": "Franklin",
        "state": "CT",
        "zipCode": "6254",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1338",
        "facilityName": "Pete and Gerry's Organics",
        "addressLine1": "24185 Mill Road",
        "addressLine2": "",
        "city": "Summers",
        "state": "AR",
        "zipCode": "72769",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1339",
        "facilityName": "Almark Foods",
        "addressLine1": "2060 Alexander Street",
        "addressLine2": "",
        "city": "Newberry",
        "state": "SC",
        "zipCode": "29108",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1341",
        "facilityName": "Michael Foods Southwest Co",
        "addressLine1": "4420 E 36th Street",
        "addressLine2": "",
        "city": "Yuma",
        "state": "AZ",
        "zipCode": "85365",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1347",
        "facilityName": "Opal Eggs LP",
        "addressLine1": "1247 90th Street",
        "addressLine2": "",
        "city": "Hampton",
        "state": "IA",
        "zipCode": "50441",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1354",
        "facilityName": "Ohio Natural Eggs LLC",
        "addressLine1": "25275 State Route 347",
        "addressLine2": "",
        "city": "Raymond",
        "state": "OH",
        "zipCode": "43067",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1356",
        "facilityName": "US Cold Storage",
        "addressLine1": "4000 AM Drive",
        "addressLine2": "",
        "city": "Quakertown",
        "state": "PA",
        "zipCode": "18951",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1360",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "3500 Egg Road",
        "addressLine2": "",
        "city": "Groveland",
        "state": "FL",
        "zipCode": "34736",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1362",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "One Tyson Avenue",
        "addressLine2": "",
        "city": "Noel",
        "state": "MO",
        "zipCode": "64854",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1363",
        "facilityName": "Pete and Gerrys Organics",
        "addressLine1": "3026 Gateway Centre Pkwy",
        "addressLine2": "",
        "city": "Gainesville",
        "state": "GA",
        "zipCode": "30501",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1368",
        "facilityName": "Cashton Farm Supply LTD",
        "addressLine1": "105 Webster Street",
        "addressLine2": "",
        "city": "Westby",
        "state": "WI",
        "zipCode": "54667",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1373",
        "facilityName": "Villa Rose",
        "addressLine1": "65-1001 Kaukanahua Road",
        "addressLine2": "",
        "city": "Waialua",
        "state": "HI",
        "zipCode": "96791",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1379",
        "facilityName": "Morning Fresh Farms",
        "addressLine1": "15121 Weld County Road 32",
        "addressLine2": "",
        "city": "Platteville",
        "state": "CO",
        "zipCode": "80651",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1380",
        "facilityName": "R. W. Sauder, Inc.",
        "addressLine1": "790 Schoeneck Road",
        "addressLine2": "",
        "city": "Ephrata",
        "state": "PA",
        "zipCode": "17522",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1382",
        "facilityName": "Benton County Food LLC",
        "addressLine1": "15080 Russell Rd",
        "addressLine2": "",
        "city": "Siloam Springs",
        "state": "AR",
        "zipCode": "72761",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1385",
        "facilityName": "Three Puglisi Brothers Inc",
        "addressLine1": "75 Easy St",
        "addressLine2": "",
        "city": "Howell",
        "state": "NJ",
        "zipCode": "7731",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1388",
        "facilityName": "Hidden Villa Ranch",
        "addressLine1": "658 Mesquit Street",
        "addressLine2": "",
        "city": "Los Angeles",
        "state": "CA",
        "zipCode": "90021",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1390",
        "facilityName": "Aegis Foods LLC",
        "addressLine1": "16975 Westview Ave",
        "addressLine2": "",
        "city": "South Holland",
        "state": "IL",
        "zipCode": "60473",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1410",
        "facilityName": "Cal-Maine Foods, Inc, Site #1",
        "addressLine1": "9729 Guthrie Road",
        "addressLine2": "",
        "city": "Guthrie",
        "state": "KY",
        "zipCode": "42234",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1411",
        "facilityName": "Cal-Maine Foods Inc., Site #2",
        "addressLine1": "9729 Guthrie Road",
        "addressLine2": "",
        "city": "Guthrie",
        "state": "KY",
        "zipCode": "42234",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1414",
        "facilityName": "Green Meadow Organic",
        "addressLine1": "3896 West Grand River Ave.",
        "addressLine2": "",
        "city": "Saranac",
        "state": "MI",
        "zipCode": "48881",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1417",
        "facilityName": "ISE America Inc",
        "addressLine1": "110 Good Springs Road",
        "addressLine2": "",
        "city": "Broadway",
        "state": "NJ",
        "zipCode": "8808",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1435",
        "facilityName": "Hidden Villa Ranch",
        "addressLine1": "32748 Reservoir Avenue",
        "addressLine2": "",
        "city": "Nuevo",
        "state": "CA",
        "zipCode": "92567",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1442",
        "facilityName": "Zoet Poultry Farm",
        "addressLine1": "4847 140th Ave",
        "addressLine2": "",
        "city": "Holland",
        "state": "MI",
        "zipCode": "49423",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1450",
        "facilityName": "Wilcox Farms, Inc.",
        "addressLine1": "40400 Harts Lake Valley Road",
        "addressLine2": "",
        "city": "Roy",
        "state": "WA",
        "zipCode": "98580",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1457",
        "facilityName": "Cal-Maine Foods, Inc.",
        "addressLine1": "234 Cal-Maine Lane",
        "addressLine2": "",
        "city": "Green Forest",
        "state": "AR",
        "zipCode": "72638",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1463",
        "facilityName": "Olivera Egg Ranch",
        "addressLine1": "3315 Sierra Rd.",
        "addressLine2": "",
        "city": "San Jose",
        "state": "CA",
        "zipCode": "95132",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1488",
        "facilityName": "Opal Foods LLC",
        "addressLine1": "9575 WCR 73",
        "addressLine2": "",
        "city": "Roggen",
        "state": "CO",
        "zipCode": "80652",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1501",
        "facilityName": "FSE Processing LLC",
        "addressLine1": "1501 East Industrial Drive",
        "addressLine2": "",
        "city": "Muscoda",
        "state": "WI",
        "zipCode": "53573",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1518",
        "facilityName": "SKS Enterprises",
        "addressLine1": "23709 E Brandt Road",
        "addressLine2": "",
        "city": "Lodi",
        "state": "CA",
        "zipCode": "95240",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1520",
        "facilityName": "Noah W Kreider & Sons",
        "addressLine1": "1400 Mount Pleasant Road",
        "addressLine2": "",
        "city": "Lebanon",
        "state": "PA",
        "zipCode": "17042",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1526",
        "facilityName": "National Food Corporation",
        "addressLine1": "25351 National Avenue",
        "addressLine2": "",
        "city": "Plankinton",
        "state": "SD",
        "zipCode": "57368",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1529",
        "facilityName": "Ramona Egg Farm",
        "addressLine1": "133 South Whitworth Road",
        "addressLine2": "",
        "city": "Newman",
        "state": "CA",
        "zipCode": "95360",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1534",
        "facilityName": "Daybreak Foods, Inc.",
        "addressLine1": "1240 8th Street",
        "addressLine2": "",
        "city": "Martin",
        "state": "MI",
        "zipCode": "49070",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1535",
        "facilityName": "Weaver Bro, Inc. dba Dew Fresh Egg Farm",
        "addressLine1": "10069 Christian Rd.",
        "addressLine2": "",
        "city": "Versailles",
        "state": "OH",
        "zipCode": "45380",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1561",
        "facilityName": "Hillandale Farms Conn LLC #1561",
        "addressLine1": "17 Schwartz Road",
        "addressLine2": "",
        "city": "Bozrah",
        "state": "CT",
        "zipCode": "6334",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1584",
        "facilityName": "Creighton Brothers LLC",
        "addressLine1": "6500 West Crystal Lake Road",
        "addressLine2": "",
        "city": "Warsaw",
        "state": "IN",
        "zipCode": "46580",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1595",
        "facilityName": "Johnson County Egg Farm",
        "addressLine1": "1275 South East Y Highway",
        "addressLine2": "",
        "city": "Knob Noster",
        "state": "MO",
        "zipCode": "65336",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1600",
        "facilityName": "Noah W Kreider & Sons",
        "addressLine1": "425 Indian Village Road",
        "addressLine2": "",
        "city": "Manheim",
        "state": "PA",
        "zipCode": "17545",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1640",
        "facilityName": "Rindler Poultry LLC",
        "addressLine1": "5424 Watkins Road",
        "addressLine2": "",
        "city": "St. Henry",
        "state": "OH",
        "zipCode": "45883",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1641",
        "facilityName": "Ross Medford Farms LLC",
        "addressLine1": "13805 Ross-Medford Road",
        "addressLine2": "",
        "city": "New Weston",
        "state": "OH",
        "zipCode": "45348",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1642",
        "facilityName": "J Star Farms LLC",
        "addressLine1": "7500 East State Route 26",
        "addressLine2": "",
        "city": "Portland",
        "state": "IN",
        "zipCode": "47371",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1643",
        "facilityName": "Green Valley Ranch LLC",
        "addressLine1": "2330 E. 300 South",
        "addressLine2": "",
        "city": "Portland",
        "state": "IN",
        "zipCode": "47371",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1644",
        "facilityName": "East Creek Farm LLC",
        "addressLine1": "8818 St. Rt 274",
        "addressLine2": "",
        "city": "New Bremen",
        "state": "OH",
        "zipCode": "45869",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1665",
        "facilityName": "Hertzfeld Poultry Farms, Inc.",
        "addressLine1": "15799 Milton Road",
        "addressLine2": "",
        "city": "Grand Rapids",
        "state": "OH",
        "zipCode": "43522",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1674",
        "facilityName": "Sparboe Farms, Inc.",
        "addressLine1": "1140 Timber Drive",
        "addressLine2": "",
        "city": "Goodell",
        "state": "IA",
        "zipCode": "50439",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1698",
        "facilityName": "Lathem Family Farms",
        "addressLine1": "1236 Wayne Poultry Road",
        "addressLine2": "",
        "city": "Pendergrass",
        "state": "GA",
        "zipCode": "30567",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1711",
        "facilityName": "Hickman's Egg Ranch",
        "addressLine1": "12710 N Murphy Rd",
        "addressLine2": "",
        "city": "Maricopa",
        "state": "AZ",
        "zipCode": "85239",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1723",
        "facilityName": "The Hennery",
        "addressLine1": "478 West Portland Road",
        "addressLine2": "",
        "city": "Lake Odessa",
        "state": "MI",
        "zipCode": "48849",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1728",
        "facilityName": "Satrum Farms",
        "addressLine1": "10140 South Hwy 211",
        "addressLine2": "",
        "city": "Molalla",
        "state": "OR",
        "zipCode": "97038",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1735",
        "facilityName": "Kreher's Farm Fresh Eggs, LLC",
        "addressLine1": "5411 Davison Rd.",
        "addressLine2": "",
        "city": "Clarence",
        "state": "NY",
        "zipCode": "14031",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1736",
        "facilityName": "Wayne County Egg Farm, LLC",
        "addressLine1": "6864 Wadsworth Rd.",
        "addressLine2": "",
        "city": "Wolcott",
        "state": "NY",
        "zipCode": "14590",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1747",
        "facilityName": "National Food NW LLC",
        "addressLine1": "290 S. Damon Road",
        "addressLine2": "",
        "city": "Lind",
        "state": "WA",
        "zipCode": "99341",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1767",
        "facilityName": "Farm Fresh Packaging, LLC",
        "addressLine1": "14460 Palm Avenue",
        "addressLine2": "",
        "city": "Wasco",
        "state": "CA",
        "zipCode": "93280",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1776",
        "facilityName": "Pleasant Valley Farms",
        "addressLine1": "30636 E. Carter Road",
        "addressLine2": "",
        "city": "Farmington",
        "state": "CA",
        "zipCode": "95230",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1789",
        "facilityName": "Farm Fresh Packaging, LLC",
        "addressLine1": "28720 Garces Hwy",
        "addressLine2": "",
        "city": "Delano",
        "state": "CA",
        "zipCode": "93215",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1821",
        "facilityName": "Rose & Shore",
        "addressLine1": "5151 Alcoa Avenue",
        "addressLine2": "",
        "city": "Vernon",
        "state": "CA",
        "zipCode": "90058",
        "service": "Scheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "1827",
        "facilityName": "Hillandale- Gettysburg, L.P.",
        "addressLine1": "1190 Tapeworm Road",
        "addressLine2": "",
        "city": "New Oxford",
        "state": "PA",
        "zipCode": "17325",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1829",
        "facilityName": "Cal-Maine Foods, Inc.",
        "addressLine1": "3078 Washington Road",
        "addressLine2": "",
        "city": "Rossburg",
        "state": "OH",
        "zipCode": "45362",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1831",
        "facilityName": "Trillium Farms Holdings LLC Site#4",
        "addressLine1": "11492 Westley Chapel Road",
        "addressLine2": "",
        "city": "Croton",
        "state": "OH",
        "zipCode": "43013",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1837",
        "facilityName": "ISE America Inc",
        "addressLine1": "112A Cemetary Rd.",
        "addressLine2": "",
        "city": "Woodstown",
        "state": "NJ",
        "zipCode": "8098",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1863",
        "facilityName": "Stiebrs Farms, Inc",
        "addressLine1": "10909 Bald Hill Road",
        "addressLine2": "",
        "city": "Yelm",
        "state": "WA",
        "zipCode": "98597",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1867",
        "facilityName": "Opal Foods LLC",
        "addressLine1": "17684 Highway D",
        "addressLine2": "",
        "city": "Neosho",
        "state": "MO",
        "zipCode": "64850",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1881",
        "facilityName": "Three Puglisi Brothers Inc",
        "addressLine1": "1881 Middle Neck Rd",
        "addressLine2": "",
        "city": "Middletown",
        "state": "DE",
        "zipCode": "19709",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1885",
        "facilityName": "Wilcox Farms - Oregon Division",
        "addressLine1": "13280 Denbrook Road NE",
        "addressLine2": "",
        "city": "Aurora",
        "state": "OR",
        "zipCode": "97002",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1888",
        "facilityName": "Dakota Layers LLP",
        "addressLine1": "22252 SD Hwy 13",
        "addressLine2": "",
        "city": "Flandreau",
        "state": "SD",
        "zipCode": "57028",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1896",
        "facilityName": "Colorado Egg LLC",
        "addressLine1": "1133 21 Road",
        "addressLine2": "",
        "city": "Grand Junction",
        "state": "CO",
        "zipCode": "81505",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1906",
        "facilityName": "Sparboe Farms Inc",
        "addressLine1": "1981 Iowa Ave",
        "addressLine2": "",
        "city": "Britt",
        "state": "IA",
        "zipCode": "50423",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1909",
        "facilityName": "Stuart Egg Farm",
        "addressLine1": "2650 350th Road",
        "addressLine2": "",
        "city": "Stuart",
        "state": "IA",
        "zipCode": "50250",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1917",
        "facilityName": "Caldwell Foods",
        "addressLine1": "469 Piney Creek Road West",
        "addressLine2": "",
        "city": "Rose Bud",
        "state": "AR",
        "zipCode": "72137",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1925",
        "facilityName": "Trillium Farms Holdings LLC Site#1",
        "addressLine1": "11995 Croton Road",
        "addressLine2": "",
        "city": "Croton",
        "state": "OH",
        "zipCode": "43013",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1928",
        "facilityName": "Hillandale Farms Conn, LLC",
        "addressLine1": "17 Schwartz Road",
        "addressLine2": "",
        "city": "Bozrah",
        "state": "CT",
        "zipCode": "6334",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1936",
        "facilityName": "Ritewood Eggs, Inc.",
        "addressLine1": "2500 South 1600 West",
        "addressLine2": "",
        "city": "Lewiston",
        "state": "UT",
        "zipCode": "84320",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1937",
        "facilityName": "The Farmer's Hen",
        "addressLine1": "699 Kapkowski Road",
        "addressLine2": "Suite 200",
        "city": "Elizabeth",
        "state": "NJ",
        "zipCode": "7201",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1943",
        "facilityName": "Cooper Farms Inc.",
        "addressLine1": "13243 Cochran Road",
        "addressLine2": "",
        "city": "Rossburg",
        "state": "OH",
        "zipCode": "45362",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1949",
        "facilityName": "Simmons Prepared Foods",
        "addressLine1": "9802 S HWY 59",
        "addressLine2": "",
        "city": "Gentry",
        "state": "AR",
        "zipCode": "72734",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1950",
        "facilityName": "MPS Egg Farms - Feather Crest",
        "addressLine1": "14374 E State Hwy 21",
        "addressLine2": "",
        "city": "Bryan",
        "state": "TX",
        "zipCode": "77808",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1951",
        "facilityName": "Rainbow Farms",
        "addressLine1": "1220 Hall Road",
        "addressLine2": "",
        "city": "Denair",
        "state": "CA",
        "zipCode": "95316",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1956",
        "facilityName": "Cal-Maine Foods, Inc",
        "addressLine1": "9246 N, 4000 W",
        "addressLine2": "",
        "city": "Delta",
        "state": "UT",
        "zipCode": "84624",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1957",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "9251 N 4000 W",
        "addressLine2": "",
        "city": "Delta",
        "state": "UT",
        "zipCode": "84624",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1970",
        "facilityName": "Giroux's Poultry Farms",
        "addressLine1": "8957 Rt. 9",
        "addressLine2": "",
        "city": "Chazy",
        "state": "NY",
        "zipCode": "12921",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1974",
        "facilityName": "Trillium Farm Holdings LLC Site#3",
        "addressLine1": "11652 Clover Valley Road",
        "addressLine2": "",
        "city": "Croton",
        "state": "OH",
        "zipCode": "43013",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1978",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "1800 State Route 2584",
        "addressLine2": "",
        "city": "Central City",
        "state": "KY",
        "zipCode": "42330",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1979",
        "facilityName": "Cal-Maine Foods, Inc.",
        "addressLine1": "11500 St., Route 181 N",
        "addressLine2": "",
        "city": "Bremen",
        "state": "KY",
        "zipCode": "42325",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1990",
        "facilityName": "Oconee Egg Farm",
        "addressLine1": "864 Reids Road NW",
        "addressLine2": "",
        "city": "Madison",
        "state": "GA",
        "zipCode": "30650",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "2000",
        "facilityName": "Hickman's Egg Ranch",
        "addressLine1": "32245 Old Salome Higway",
        "addressLine2": "",
        "city": "Arlington",
        "state": "AZ",
        "zipCode": "85326",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "2015",
        "facilityName": "Hickman's Egg Ranch",
        "addressLine1": "41717 W Indian School Road",
        "addressLine2": "",
        "city": "Tonopah",
        "state": "AZ",
        "zipCode": "85354",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "2030",
        "facilityName": "Hickman's Egg Ranch",
        "addressLine1": "Arlington North",
        "addressLine2": "32902  West Ward Road",
        "city": "Arlington",
        "state": "AZ",
        "zipCode": "85322",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "2101",
        "facilityName": "Hillandale Farms Conn LLC",
        "addressLine1": "272 Plains Road",
        "addressLine2": "",
        "city": "Turner",
        "state": "ME",
        "zipCode": "4282",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "2103",
        "facilityName": "Hillandale Farms Conn LLC",
        "addressLine1": "272 Plains Road",
        "addressLine2": "",
        "city": "Turner",
        "state": "ME",
        "zipCode": "4828",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "2105",
        "facilityName": "Hillandale Farms Conn LLC",
        "addressLine1": "272 Plains Road",
        "addressLine2": "",
        "city": "Turner",
        "state": "ME",
        "zipCode": "4282",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "2111",
        "facilityName": "Granite Ridge Farm LLC",
        "addressLine1": "2532 S Apple Creek Rd",
        "addressLine2": "",
        "city": "Apple Creek",
        "state": "OH",
        "zipCode": "44606",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "2130",
        "facilityName": "Cooper Foods",
        "addressLine1": "#1 Cooper Farms Drive",
        "addressLine2": "",
        "city": "St. Henry",
        "state": "OH",
        "zipCode": "45883",
        "service": "Unscheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "2178",
        "facilityName": "Perdue, Inc.",
        "addressLine1": "20621 Savannah Drive",
        "addressLine2": "",
        "city": "Georgetown",
        "state": "DE",
        "zipCode": "19947",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "2186",
        "facilityName": "George's Foods, Inc.",
        "addressLine1": "501 North Liberty Street",
        "addressLine2": "",
        "city": "Harrisonburg",
        "state": "VA",
        "zipCode": "22802",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "2632",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "19740 US Highway 90",
        "addressLine2": "",
        "city": "Live Oak",
        "state": "FL",
        "zipCode": "32060",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "3250",
        "facilityName": "CLR Eggs LLC",
        "addressLine1": "13250 State Route 118",
        "addressLine2": "",
        "city": "Rossburg",
        "state": "OH",
        "zipCode": "45362",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "3447",
        "facilityName": "Daybreak Foods, Inc.",
        "addressLine1": "2127 110th Avenue",
        "addressLine2": "",
        "city": "Otsego",
        "state": "MI",
        "zipCode": "49078",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "3505",
        "facilityName": "Dakota Gobblers Cold Storage",
        "addressLine1": "701 Lincoln Ave NW",
        "addressLine2": "",
        "city": "Huron",
        "state": "SD",
        "zipCode": "57350",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "3861",
        "facilityName": "Hickmans Egg Ranch",
        "addressLine1": "275 W. Lower Buckeye Road",
        "addressLine2": "",
        "city": "Avondale",
        "state": "AZ",
        "zipCode": "85323",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "3905",
        "facilityName": "Wenning Poultry Farm",
        "addressLine1": "1500 Union City Road",
        "addressLine2": "",
        "city": "Fort Recovery",
        "state": "OH",
        "zipCode": "45846",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "4894",
        "facilityName": "CenCal Foods LLC",
        "addressLine1": "1828 E Hedges Ave",
        "addressLine2": "",
        "city": "Fresno",
        "state": "CA",
        "zipCode": "93703",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "5150",
        "facilityName": "Sunrise Farms LLC",
        "addressLine1": "395 Liberty Road",
        "addressLine2": "",
        "city": "Petaluma",
        "state": "CA",
        "zipCode": "94952",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "5533",
        "facilityName": "West Liberty Foods-West Liberty",
        "addressLine1": "207 W 2nd Street",
        "addressLine2": "",
        "city": "West Liberty",
        "state": "IA",
        "zipCode": "52776",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "5787",
        "facilityName": "Pilgrims Pride Corp",
        "addressLine1": "7088 Hwy.1 South Bypass",
        "addressLine2": "",
        "city": "Natchitoches",
        "state": "LA",
        "zipCode": "71457",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "5842",
        "facilityName": "Tyson Foods Inc.",
        "addressLine1": "600 North Berry Street",
        "addressLine2": "",
        "city": "Springdale",
        "state": "AR",
        "zipCode": "72764",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "6058",
        "facilityName": "Draper Valley Farms",
        "addressLine1": "1000 Jason Lane",
        "addressLine2": "",
        "city": "Mount Vernon",
        "state": "WA",
        "zipCode": "98273",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "6137",
        "facilityName": "Foster Food Products",
        "addressLine1": "843 Davis Street",
        "addressLine2": "",
        "city": "Livingston",
        "state": "CA",
        "zipCode": "95334",
        "service": "Scheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "6425",
        "facilityName": "Herbruck Poultry Ranch Inc",
        "addressLine1": "6425 W. Grand River Avenue",
        "addressLine2": "",
        "city": "Saranac",
        "state": "MI",
        "zipCode": "48881",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "6504",
        "facilityName": "Peco Foods",
        "addressLine1": "3701 Reese Phifer Avenue",
        "addressLine2": "",
        "city": "Tuscaloosa",
        "state": "AL",
        "zipCode": "35401",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "6519",
        "facilityName": "Crider, Inc.",
        "addressLine1": "1 Plant Avenue",
        "addressLine2": "",
        "city": "Stillmore",
        "state": "GA",
        "zipCode": "30464",
        "service": "Scheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "6562",
        "facilityName": "Gemperle Enterprises, August Plant",
        "addressLine1": "18524 August Road",
        "addressLine2": "",
        "city": "Hilmar",
        "state": "CA",
        "zipCode": "95324",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "6638",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "4693 County Road 636",
        "addressLine2": "",
        "city": "Enterprise",
        "state": "AL",
        "zipCode": "36331",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "6651",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "901 W Jackson Street",
        "addressLine2": "",
        "city": "Shelbyville",
        "state": "TN",
        "zipCode": "37160",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "6930",
        "facilityName": "Rose Packing Co",
        "addressLine1": "4900 South Major Ave",
        "addressLine2": "",
        "city": "Chicago",
        "state": "IL",
        "zipCode": "60638",
        "service": "Unscheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "7044",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "1484 NE Loop",
        "addressLine2": "",
        "city": "Carthage",
        "state": "TX",
        "zipCode": "75633",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7048",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "802 S. 28th St.",
        "addressLine2": "",
        "city": "Van Buren",
        "state": "AR",
        "zipCode": "72956",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7085",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "Highway 259-70 West",
        "addressLine2": "",
        "city": "Broken Bow",
        "state": "OK",
        "zipCode": "74728",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7089",
        "facilityName": "Tyson Foods",
        "addressLine1": "1001 East Stoddard St",
        "addressLine2": "",
        "city": "Dexter",
        "state": "MO",
        "zipCode": "63841",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7091",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "1030 Pilgrims Street",
        "addressLine2": "",
        "city": "Mt. Pleasant",
        "state": "TX",
        "zipCode": "75455",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7092",
        "facilityName": "Kings Command Foods LLC",
        "addressLine1": "7300 Southwest 29th Street",
        "addressLine2": "",
        "city": "Oklahoma City",
        "state": "OK",
        "zipCode": "73179",
        "service": "Unscheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "7101",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "301 E. Cherry Street",
        "addressLine2": "",
        "city": "Clarksville",
        "state": "AR",
        "zipCode": "72830",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7117",
        "facilityName": "Cargill Meat Solutions",
        "addressLine1": "2601 Industrial Road",
        "addressLine2": "",
        "city": "Nebraska City",
        "state": "NE",
        "zipCode": "68410",
        "service": "Scheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "7174",
        "facilityName": "Butterball LLC",
        "addressLine1": "1294 N. College St.",
        "addressLine2": "",
        "city": "Huntsville",
        "state": "AR",
        "zipCode": "72740",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "7183",
        "facilityName": "Gemperle Barnhart Ranch",
        "addressLine1": "718 East Barnhart Road",
        "addressLine2": "",
        "city": "Ceres",
        "state": "CA",
        "zipCode": "95307",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "7211",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "110-112 West Freeman Street",
        "addressLine2": "",
        "city": "Berryville",
        "state": "AR",
        "zipCode": "72616",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7345",
        "facilityName": "Butterball, LLC",
        "addressLine1": "1628 Garners Chapel Rd",
        "addressLine2": "",
        "city": "Mount Olive",
        "state": "NC",
        "zipCode": "28365",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "7470",
        "facilityName": "Mountaire Farms",
        "addressLine1": "17269 NC Hwy 71 N",
        "addressLine2": "",
        "city": "Lumber Bridge",
        "state": "NC",
        "zipCode": "28357",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7632",
        "facilityName": "Foster Farms",
        "addressLine1": "900 West Belgravia",
        "addressLine2": "",
        "city": "Fresno",
        "state": "CA",
        "zipCode": "93706",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7669",
        "facilityName": "Turkey Valley Farms",
        "addressLine1": "112 South 6th Street",
        "addressLine2": "",
        "city": "Marshall",
        "state": "MN",
        "zipCode": "56258",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "7903",
        "facilityName": "Perdue Farms, Inc.",
        "addressLine1": "22520 Charles M. Lankford Jr. Memorial Hwy",
        "addressLine2": "",
        "city": "Accomac",
        "state": "VA",
        "zipCode": "23301",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7927",
        "facilityName": "Amick Farms, LLC",
        "addressLine1": "274 Nealson Street",
        "addressLine2": "",
        "city": "Hurlock",
        "state": "MD",
        "zipCode": "21643",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7935",
        "facilityName": "Cargill Timberville",
        "addressLine1": "480 Coop Drive",
        "addressLine2": "Attn:  1017-A334",
        "city": "Timberville",
        "state": "VA",
        "zipCode": "22853",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7987",
        "facilityName": "Amick Farms",
        "addressLine1": "2079 Batesburg Highway",
        "addressLine2": "",
        "city": "Batesburg-Leesville",
        "state": "SC",
        "zipCode": "29070",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "8069",
        "facilityName": "Herbrucks of Pennsylvania LLC",
        "addressLine1": "8069 Corner Road",
        "addressLine2": "",
        "city": "Mercersburg",
        "state": "PA",
        "zipCode": "17236",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "8334",
        "facilityName": "Vanguard Culinary Group, Inc",
        "addressLine1": "716 Whitfield Street",
        "addressLine2": "",
        "city": "Fayetteville",
        "state": "NC",
        "zipCode": "28306",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "8437",
        "facilityName": "Lewistown Valley Inc dba Koch's Turkey Farm",
        "addressLine1": "416 Valley Road",
        "addressLine2": "",
        "city": "Tamaqua",
        "state": "PA",
        "zipCode": "18252",
        "service": "Unscheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "8631",
        "facilityName": "Minnich Poultry",
        "addressLine1": "8631 E 300 N",
        "addressLine2": "",
        "city": "Portland",
        "state": "IN",
        "zipCode": "47371",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "8657",
        "facilityName": "Minnich Poultry, LLC",
        "addressLine1": "8657 E 300 N",
        "addressLine2": "",
        "city": "Portland",
        "state": "IN",
        "zipCode": "47371",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "8727",
        "facilityName": "Butterball, LLC",
        "addressLine1": "411 North Main",
        "addressLine2": "",
        "city": "Carthage",
        "state": "MO",
        "zipCode": "64836",
        "service": "Unscheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "8849",
        "facilityName": "Gemperle Enterprises, Channing Ranch",
        "addressLine1": "9488 Lander Avenue",
        "addressLine2": "",
        "city": "Turlock",
        "state": "CA",
        "zipCode": "95380",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "9136",
        "facilityName": "House of Raeford Farms Inc",
        "addressLine1": "1344 Battery Park Road",
        "addressLine2": "",
        "city": "Nesmith",
        "state": "SC",
        "zipCode": "29580",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "9165",
        "facilityName": "Gold Creek #10",
        "addressLine1": "2076 Memorial Park Road",
        "addressLine2": "",
        "city": "Gainesville",
        "state": "GA",
        "zipCode": "30504",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "9197",
        "facilityName": "Perdue Farms Inc",
        "addressLine1": "PO Box 460",
        "addressLine2": "3539 Governors Road",
        "city": "Lewiston",
        "state": "NC",
        "zipCode": "27849",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "13556",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "19571 Whitfield Road",
        "addressLine2": "",
        "city": "Sedalia",
        "state": "MO",
        "zipCode": "65302",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "14341",
        "facilityName": "West Liberty Foods",
        "addressLine1": "750 Schmidt Road",
        "addressLine2": "",
        "city": "Bolingbrook",
        "state": "IL",
        "zipCode": "60440",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "15854",
        "facilityName": "John Hofmeister & Son, Inc",
        "addressLine1": "2386 S Blue Island Ave",
        "addressLine2": "",
        "city": "Chicago",
        "state": "IL",
        "zipCode": "60608",
        "service": "Unscheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "17065",
        "facilityName": "King Meat Service Inc",
        "addressLine1": "4215 Exchange Ave",
        "addressLine2": "",
        "city": "Vernon",
        "state": "CA",
        "zipCode": "90058",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "17340",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "2653 State Route 1241",
        "addressLine2": "",
        "city": "Hickory",
        "state": "KY",
        "zipCode": "42051",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "17341",
        "facilityName": "Keystone Foods Inc",
        "addressLine1": "2281 Steele Station Road",
        "addressLine2": "",
        "city": "Gadsden",
        "state": "AL",
        "zipCode": "35906",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "17453",
        "facilityName": "Tip  Top Poultry",
        "addressLine1": "479 Nathan Dean Parkway",
        "addressLine2": "",
        "city": "Rockmart",
        "state": "GA",
        "zipCode": "30153",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "17500",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "2045 Highway 244",
        "addressLine2": "",
        "city": "Russellville",
        "state": "AL",
        "zipCode": "35653",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "17728",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "1785 Interplex Drive",
        "addressLine2": "",
        "city": "Vicksburg",
        "state": "MS",
        "zipCode": "39183",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "17766",
        "facilityName": "Southern Hens",
        "addressLine1": "329 Moselle-Seminary Rd",
        "addressLine2": "",
        "city": "Moselle",
        "state": "MS",
        "zipCode": "39459",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "17813",
        "facilityName": "Artico Cold Storage Chicago LLC",
        "addressLine1": "1556 W 43rd St.",
        "addressLine2": "",
        "city": "Chicago",
        "state": "IL",
        "zipCode": "60609",
        "service": "Unscheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "17980",
        "facilityName": "Pilgrims Pride Corp",
        "addressLine1": "2050 Hwy. 15, South",
        "addressLine2": "",
        "city": "Sumter",
        "state": "SC",
        "zipCode": "29150",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "18285",
        "facilityName": "Perdue Farms Inc",
        "addressLine1": "2047 Hwy 9 West",
        "addressLine2": "",
        "city": "Dillon",
        "state": "SC",
        "zipCode": "29536",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "18491",
        "facilityName": "Eastern Shore Poultry Co.",
        "addressLine1": "21724 Broad Creek",
        "addressLine2": "",
        "city": "Georgetown",
        "state": "DE",
        "zipCode": "19947",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "18557",
        "facilityName": "Sanderson Farms Processing Inc",
        "addressLine1": "4039 River Ridge Road",
        "addressLine2": "",
        "city": "Summit",
        "state": "MS",
        "zipCode": "39666",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "18873",
        "facilityName": "Tyson Foods, Inc.",
        "addressLine1": "1151 East Pine Street",
        "addressLine2": "",
        "city": "Vienna",
        "state": "GA",
        "zipCode": "31092",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "18967",
        "facilityName": "Claxton Cold Storage",
        "addressLine1": "6424 Hwy 301 South",
        "addressLine2": "",
        "city": "Claxton",
        "state": "GA",
        "zipCode": "30417",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "19056",
        "facilityName": "Lets Do Lunch Inc.",
        "addressLine1": "310 West Alondra Blvd.",
        "addressLine2": "",
        "city": "Gardena",
        "state": "CA",
        "zipCode": "90248",
        "service": "Unscheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "19112",
        "facilityName": "Perdue Farms, Inc.",
        "addressLine1": "5025 Highway 231 South",
        "addressLine2": "",
        "city": "Beaver Dam",
        "state": "KY",
        "zipCode": "42320",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "19299",
        "facilityName": "Jennie-O Turkey Store",
        "addressLine1": "901 N 24th Street",
        "addressLine2": "",
        "city": "Montevideo",
        "state": "MN",
        "zipCode": "56265",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "19514",
        "facilityName": "Tyson Foods, Inc",
        "addressLine1": "14660 U.S. Highway 41 South",
        "addressLine2": "",
        "city": "Robards",
        "state": "KY",
        "zipCode": "42452",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "19688",
        "facilityName": "Sanderson Farms Processing LLC",
        "addressLine1": "2000 Shiloh Drive",
        "addressLine2": "",
        "city": "Bryan",
        "state": "TX",
        "zipCode": "77803",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "19719",
        "facilityName": "Twin Rivers Foods",
        "addressLine1": "201 N. 2nd Street",
        "addressLine2": "",
        "city": "Fort Smith",
        "state": "AR",
        "zipCode": "72901",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "20481",
        "facilityName": "Bluebonnet Foods, L.P.",
        "addressLine1": "11122 Nacogdoches St.",
        "addressLine2": "",
        "city": "San Antonio",
        "state": "TX",
        "zipCode": "78217",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "20728",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "2500 E. Lakeshore Drive",
        "addressLine2": "",
        "city": "Waco",
        "state": "TX",
        "zipCode": "76705",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "21234",
        "facilityName": "Perdue Farms, Inc.",
        "addressLine1": "250 GA Hwy 247 Spur",
        "addressLine2": "",
        "city": "Perry",
        "state": "GA",
        "zipCode": "31069",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "21683",
        "facilityName": "Deb El Food",
        "addressLine1": "900 North Linn Avenue",
        "addressLine2": "",
        "city": "New Hampton",
        "state": "IA",
        "zipCode": "50659",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "21931",
        "facilityName": "Rembrandt Enterprises",
        "addressLine1": "1419 480th Street",
        "addressLine2": "",
        "city": "Rembrandt",
        "state": "IA",
        "zipCode": "50576",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "22000",
        "facilityName": "Prestage Foods Inc",
        "addressLine1": "4470 NC Hwy 20",
        "addressLine2": "",
        "city": "St. Pauls",
        "state": "NC",
        "zipCode": "28384",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "27391",
        "facilityName": "West Liberty Foods - Mt Pleasant",
        "addressLine1": "2225 East Commerce Drive",
        "addressLine2": "",
        "city": "Mt Pleasant",
        "state": "IA",
        "zipCode": "52641",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "31639",
        "facilityName": "Everest Meat Company",
        "addressLine1": "1617 East 25th Street",
        "addressLine2": "",
        "city": "Los Angeles",
        "state": "CA",
        "zipCode": "90011",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "31727",
        "facilityName": "Kiryas Joel Poultry Processing Plant",
        "addressLine1": "7 Dinev Road",
        "addressLine2": "",
        "city": "Monroe",
        "state": "NY",
        "zipCode": "10950",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "31740",
        "facilityName": "Americold #31740",
        "addressLine1": "21755 Cedar Avenue",
        "addressLine2": "",
        "city": "Lakeville",
        "state": "MN",
        "zipCode": "55044",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "31784",
        "facilityName": "John Soules Foods",
        "addressLine1": "1150 Aviation Blvd.",
        "addressLine2": "",
        "city": "Gainesville",
        "state": "GA",
        "zipCode": "30501",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "31877",
        "facilityName": "Twin Rivers Foods, Inc.",
        "addressLine1": "602 Southeast 1st Street",
        "addressLine2": "",
        "city": "Atkins",
        "state": "AR",
        "zipCode": "72823",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "31979",
        "facilityName": "Gold Creek Processing",
        "addressLine1": "1100 Airport Parkway",
        "addressLine2": "",
        "city": "Gainesville",
        "state": "GA",
        "zipCode": "30501",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "32130",
        "facilityName": "Dakota Provisions",
        "addressLine1": "20827 403rd Ave",
        "addressLine2": "",
        "city": "Huron",
        "state": "SD",
        "zipCode": "57350",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "32182",
        "facilityName": "Sanderson Farms Processing LLC",
        "addressLine1": "700 HWY 133 S",
        "addressLine2": "",
        "city": "Moultrie",
        "state": "GA",
        "zipCode": "31776",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "33900",
        "facilityName": "Foster Farms",
        "addressLine1": "6648 Hwy 15 North",
        "addressLine2": "",
        "city": "Farmerville",
        "state": "LA",
        "zipCode": "71241",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "34349",
        "facilityName": "West Liberty Foods, L.L.C.",
        "addressLine1": "705 North 2000 West",
        "addressLine2": "",
        "city": "Tremonton",
        "state": "UT",
        "zipCode": "94337",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "34805",
        "facilityName": "Gold Creek Processing",
        "addressLine1": "2069 Memorial Park",
        "addressLine2": "",
        "city": "Gainesville",
        "state": "GA",
        "zipCode": "30504",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "40124",
        "facilityName": "Gold Creek Processing",
        "addressLine1": "2080 Industrial Drive",
        "addressLine2": "",
        "city": "Gainesville",
        "state": "GA",
        "zipCode": "30504",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "40183",
        "facilityName": "Sanderson Farms Processing LLC",
        "addressLine1": "2081 Sanderson Way",
        "addressLine2": "",
        "city": "Kinston",
        "state": "NC",
        "zipCode": "28504",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "45134",
        "facilityName": "Birdsboro Kosher Farms Corp",
        "addressLine1": "1100 Lincoln Road",
        "addressLine2": "",
        "city": "Birdsboro",
        "state": "PA",
        "zipCode": "19508",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "45192",
        "facilityName": "Out of the Shell LLC, dba-Yangs",
        "addressLine1": "1705 Chico Ave",
        "addressLine2": "",
        "city": "South El Monte",
        "state": "CA",
        "zipCode": "91733",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "45379",
        "facilityName": "Lake Foods LLC",
        "addressLine1": "355 Industrial Park Road",
        "addressLine2": "",
        "city": "Hartwell",
        "state": "GA",
        "zipCode": "30643",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "45565",
        "facilityName": "Farm Fresh Foods, LLC",
        "addressLine1": "5016 Grimes Drive",
        "addressLine2": "",
        "city": "Guntersville",
        "state": "AL",
        "zipCode": "35976",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "45638",
        "facilityName": "Trilogy Foods LLC",
        "addressLine1": "1445 Josh Pirkle Rd",
        "addressLine2": "",
        "city": "Braselton",
        "state": "GA",
        "zipCode": "30517",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "45682",
        "facilityName": "Sky Blue Enterprises LLC",
        "addressLine1": "1001 W 45th St Suite B-1",
        "addressLine2": "",
        "city": "Chicago",
        "state": "IL",
        "zipCode": "60609",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "46049",
        "facilityName": "Proportion Foods",
        "addressLine1": "101 Chisholm Trail Road",
        "addressLine2": "",
        "city": "Round Rock",
        "state": "TX",
        "zipCode": "78681",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "46205",
        "facilityName": "Dakota Provisions",
        "addressLine1": "2020 Center Street West",
        "addressLine2": "",
        "city": "Huron",
        "state": "SD",
        "zipCode": "57350",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "46255",
        "facilityName": "RCF dba Gemstone Foods",
        "addressLine1": "700 S. Wood Avenue",
        "addressLine2": "",
        "city": "Florence",
        "state": "AL",
        "zipCode": "35630",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "46374",
        "facilityName": "Sanderson Farms Processing LLC",
        "addressLine1": "PO Box 630",
        "addressLine2": "",
        "city": "Winona",
        "state": "TX",
        "zipCode": "75792",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "46549",
        "facilityName": "Gold Creek Processing LLC #6",
        "addressLine1": "400 Highland Terrace",
        "addressLine2": "",
        "city": "Gainesville",
        "state": "GA",
        "zipCode": "30501",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "46599",
        "facilityName": "Claxton Cold Storage - Statesboro Warehouse",
        "addressLine1": "12319 South Highway 301",
        "addressLine2": "",
        "city": "Statesboro",
        "state": "GA",
        "zipCode": "30458",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "46870",
        "facilityName": "Butterball LLC",
        "addressLine1": "1000 E Central Avenue",
        "addressLine2": "",
        "city": "Raeford",
        "state": "NC",
        "zipCode": "28376",
        "service": "Scheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "47579",
        "facilityName": "Tyson Foods Inc",
        "addressLine1": "550 Tyson Blvd",
        "addressLine2": "PO Box 308",
        "city": "Humboldt",
        "state": "TN",
        "zipCode": "38343",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "48098",
        "facilityName": "Mistica Foods",
        "addressLine1": "50 W. Commercial Avenue",
        "addressLine2": "",
        "city": "Addison",
        "state": "IL",
        "zipCode": "60101",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "48304",
        "facilityName": "Lincoln Premium Poultry LLC",
        "addressLine1": "1325 E Cloverly",
        "addressLine2": "",
        "city": "Fremont",
        "state": "NE",
        "zipCode": "68026",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "51350",
        "facilityName": "M&E Processing LLC",
        "addressLine1": "5016 Grimes Drive",
        "addressLine2": "",
        "city": "Guntersville",
        "state": "AL",
        "zipCode": "35976",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "104I",
        "facilityName": "OSI Industries LLC Oakland",
        "addressLine1": "21876 North HWY 59",
        "addressLine2": "",
        "city": "Oakland",
        "state": "IA",
        "zipCode": "51560",
        "service": "Unscheduled",
        "product": "Turkey"
    },
    {
        "plantNo": "1050C",
        "facilityName": "Cal-Maine Farms, Inc.",
        "addressLine1": "3280 Adams Lane",
        "addressLine2": "",
        "city": "Edwards",
        "state": "MS",
        "zipCode": "39066",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1088A",
        "facilityName": "S & R Egg Farm",
        "addressLine1": "N 9416 Tamarack Rd",
        "addressLine2": "",
        "city": "Whitewater",
        "state": "WI",
        "zipCode": "53190",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1088old",
        "facilityName": "Cold Spring Egg Farm",
        "addressLine1": "W2024 HWY 59",
        "addressLine2": "",
        "city": "Palmyra",
        "state": "WI",
        "zipCode": "51356",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "112A",
        "facilityName": "Tyson Foods Inc",
        "addressLine1": "601 Tyson Drive Building 2",
        "addressLine2": "",
        "city": "Green Forest",
        "state": "AR",
        "zipCode": "72638",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1174B",
        "facilityName": "RCF, LLC dba Gemstone Foods",
        "addressLine1": "805 McEntire Lane",
        "addressLine2": "",
        "city": "Decatur",
        "state": "AL",
        "zipCode": "35601",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1176A",
        "facilityName": "Cal-Maine Farms, Inc.",
        "addressLine1": "1680 County Road 431",
        "addressLine2": "",
        "city": "Waelder",
        "state": "TX",
        "zipCode": "78959",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1176B",
        "facilityName": "Cal-Maine Farms, Inc.",
        "addressLine1": "748 County Road 422",
        "addressLine2": "",
        "city": "Waelder",
        "state": "TX",
        "zipCode": "78959",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1176K",
        "facilityName": "Cal-Maine Foods, Inc.",
        "addressLine1": "967 County Rd. 401",
        "addressLine2": "",
        "city": "Flatonia",
        "state": "TX",
        "zipCode": "78941",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1178A",
        "facilityName": "ISE America Inc",
        "addressLine1": "1526 Cecilton Warwick Rd",
        "addressLine2": "",
        "city": "Cecilton",
        "state": "MD",
        "zipCode": "21913",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1178B",
        "facilityName": "ISE America Inc",
        "addressLine1": "605 Sassafras Road",
        "addressLine2": "",
        "city": "Warwick",
        "state": "MD",
        "zipCode": "21912",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1185A",
        "facilityName": "Weaver Bros., Inc., dba A-1 Egg Farm",
        "addressLine1": "10638 St. Rt. 47",
        "addressLine2": "",
        "city": "Versailles",
        "state": "OH",
        "zipCode": "45380",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1186A",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "9513 Hwy 121 South",
        "addressLine2": "",
        "city": "Hoboken",
        "state": "GA",
        "zipCode": "31542",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1359C",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "13660 O'Conner Road",
        "addressLine2": "",
        "city": "Kathleen",
        "state": "FL",
        "zipCode": "33849",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1379A",
        "facilityName": "Morning Fresh Farms, Inc.",
        "addressLine1": "15121 WCR 32",
        "addressLine2": "",
        "city": "Platteville",
        "state": "CO",
        "zipCode": "80651",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1415F",
        "facilityName": "Fassio Egg Farms, Inc.",
        "addressLine1": "3044 West Erda Way",
        "addressLine2": "",
        "city": "Erda",
        "state": "UT",
        "zipCode": "84074",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1452B",
        "facilityName": "Briarwood Farms",
        "addressLine1": "19210 Anderson Road, SW",
        "addressLine2": "",
        "city": "Rochester",
        "state": "WA",
        "zipCode": "98579",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1522A",
        "facilityName": "Wharton County",
        "addressLine1": "2403 FM 1096",
        "addressLine2": "",
        "city": "Boling",
        "state": "TX",
        "zipCode": "77420",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1522B",
        "facilityName": "Wharton County",
        "addressLine1": "4429 FM 442",
        "addressLine2": "",
        "city": "Boling",
        "state": "TX",
        "zipCode": "77420",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1547S",
        "facilityName": "ISE Newberry",
        "addressLine1": "24512 Highway 34",
        "addressLine2": "",
        "city": "Silverstreet",
        "state": "SC",
        "zipCode": "29145",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "165H",
        "facilityName": "OK Foods",
        "addressLine1": "200 Industrial Road",
        "addressLine2": "",
        "city": "Heavener",
        "state": "OK",
        "zipCode": "74937",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "165S",
        "facilityName": "OK Foods, Inc",
        "addressLine1": "4601 N. 6th Street",
        "addressLine2": "",
        "city": "Fort Smith",
        "state": "AR",
        "zipCode": "72902",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1664D",
        "facilityName": "Cal-Maine Strube 1",
        "addressLine1": "7520 FM 556",
        "addressLine2": "",
        "city": "Pittsburg",
        "state": "TX",
        "zipCode": "75686",
        "service": "Unscheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1664E",
        "facilityName": "Cal-Maine Foods",
        "addressLine1": "340 County Rd 3320",
        "addressLine2": "",
        "city": "Pittsburg",
        "state": "TX",
        "zipCode": "75686",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1691B",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "6800 NE 120th Street",
        "addressLine2": "",
        "city": "Okeechobee",
        "state": "FL",
        "zipCode": "34972",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1691C",
        "facilityName": "Cal-Maine Foods Inc",
        "addressLine1": "37849 Trilby Road",
        "addressLine2": "",
        "city": "Trilby",
        "state": "FL",
        "zipCode": "33593",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1813A",
        "facilityName": "Sunrise Acres Egg Farms Complex #1",
        "addressLine1": "2558 32nd Avenue",
        "addressLine2": "",
        "city": "Hudsonville",
        "state": "MI",
        "zipCode": "49426",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1862A",
        "facilityName": "Cal-Maine Foods",
        "addressLine1": "7859 HWY 177 South",
        "addressLine2": "",
        "city": "Sulphur",
        "state": "OK",
        "zipCode": "73086",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1862B",
        "facilityName": "Cal-Maine Foods",
        "addressLine1": "21080 CR 87 N",
        "addressLine2": "",
        "city": "Chillicothe",
        "state": "TX",
        "zipCode": "79225",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1867B",
        "facilityName": "Opal Foods LLC",
        "addressLine1": "17331 Highway D",
        "addressLine2": "",
        "city": "Neosho",
        "state": "MO",
        "zipCode": "64850",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "1867C",
        "facilityName": "Opal Foods LLC",
        "addressLine1": "2950 Route F",
        "addressLine2": "",
        "city": "Anderson",
        "state": "MO",
        "zipCode": "64831",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "19056A",
        "facilityName": "Let's Do Lunch",
        "addressLine1": "354 W. Alondra Blvd.",
        "addressLine2": "",
        "city": "Gardena",
        "state": "CA",
        "zipCode": "90248",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "19719E",
        "facilityName": "Twin Rivers Foods",
        "addressLine1": "124 N E Street",
        "addressLine2": "",
        "city": "Fort Smith",
        "state": "AR",
        "zipCode": "72901",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "1AA",
        "facilityName": "Tyson Foods Inc",
        "addressLine1": "706 Factory Street",
        "addressLine2": "",
        "city": "Wilkesboro",
        "state": "NC",
        "zipCode": "28697",
        "service": "Scheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "20322A",
        "facilityName": "Tyson Foods - Eufaula",
        "addressLine1": "PO BOX 2020",
        "addressLine2": "AP AR07693 - USDA",
        "city": "Springdale",
        "state": "AR",
        "zipCode": "72765",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "20699A",
        "facilityName": "Alatrade Foods, LLC",
        "addressLine1": "695 W. Henderson Street",
        "addressLine2": "",
        "city": "Boaz",
        "state": "AL",
        "zipCode": "35957",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "21869A",
        "facilityName": "Ling's",
        "addressLine1": "212 W. Santa Fe Street",
        "addressLine2": "",
        "city": "Pomona",
        "state": "CA",
        "zipCode": "91767",
        "service": "Unscheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "27505A",
        "facilityName": "Gold Creek Processing",
        "addressLine1": "2255 White Sulphur Road",
        "addressLine2": "",
        "city": "Gainesville",
        "state": "GA",
        "zipCode": "30501",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "48098M",
        "facilityName": "Mistica Foods LLC",
        "addressLine1": "1811 W Fullerton Ave",
        "addressLine2": "",
        "city": "Addison",
        "state": "IL",
        "zipCode": "60101",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "533A",
        "facilityName": "Poultry Holdings dba FreeBird Chicken",
        "addressLine1": "2609 Rt 22",
        "addressLine2": "",
        "city": "Fredericksburg",
        "state": "PA",
        "zipCode": "17026",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "5886A",
        "facilityName": "Goodman Food Products",
        "addressLine1": "812 South 5th Avenue",
        "addressLine2": "",
        "city": "Mansfield",
        "state": "TX",
        "zipCode": "76063",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "6137A",
        "facilityName": "Foster Food Products",
        "addressLine1": "2960 South Cherry",
        "addressLine2": "",
        "city": "Fresno",
        "state": "CA",
        "zipCode": "93706",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "6164A",
        "facilityName": "Foster Farms",
        "addressLine1": "1700 S 13th",
        "addressLine2": "",
        "city": "Kelso",
        "state": "WA",
        "zipCode": "98626",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "7091A",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "1105 Monticello Road",
        "addressLine2": "",
        "city": "Mt. Pleasant",
        "state": "TX",
        "zipCode": "75455",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "855D",
        "facilityName": "Pilgrims Pride Corporation",
        "addressLine1": "898 Barber Street",
        "addressLine2": "",
        "city": "Athens",
        "state": "GA",
        "zipCode": "30601",
        "service": "Scheduled",
        "product": "Chicken"
    },
    {
        "plantNo": "920A",
        "facilityName": "Cal-Maine Foods, Inc.",
        "addressLine1": "1522 Flatonia Airfield Lane",
        "addressLine2": "",
        "city": "Flatonia",
        "state": "TX",
        "zipCode": "78941",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "920H",
        "facilityName": "Cal-Maine Foods, Inc.",
        "addressLine1": "County Road 141B",
        "addressLine2": "",
        "city": "Harwood",
        "state": "TX",
        "zipCode": "78632",
        "service": "Scheduled",
        "product": "Shell Eggs"
    },
    {
        "plantNo": "9267A",
        "facilityName": "Bruce Pac",
        "addressLine1": "811 North 1st Street",
        "addressLine2": "",
        "city": "Silverton",
        "state": "OR",
        "zipCode": "97381",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    },
    {
        "plantNo": "9267B",
        "facilityName": "Bruce Pac",
        "addressLine1": "380 S. Pacific Hwy 99 East",
        "addressLine2": "",
        "city": "Woodburn",
        "state": "OR",
        "zipCode": "97071",
        "service": "Unscheduled",
        "product": "Chicken/Turkey"
    }
];