export const ENV = process.env.REACT_APP_CS_WIDGET_ENV || 'PRODUCTION';

export const URL = {
    STAGING: {
        BASE: 'https://d1fg0xr2oh0xz7.cloudfront.net',
        RECIPE_LIST_30_ID: '34',
        RECIPE_LIST_45_ID: '34',
    },
    PRODUCTION: {
        BASE: 'https://d92f495ogyf88.cloudfront.net',
        RECIPE_LIST_30_ID: '85',
        RECIPE_LIST_45_ID: '86',
    }
};